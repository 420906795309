export const applicationUpdateState = (data) => ({
  type: 'APPLICATION_UPDATE_STATE',
  data: data,
});


export const applicationLoadData = (path, options) => ({
  type: 'APPLICATION_LOAD_DATA',
  path: path,
  options: options,
});

export const applicationSubmitData = (path, options) => ({
  type: 'APPLICATION_SUBMIT_DATA',
  path: path,
  options: options,
});

export const applicationDataError = (path, error) => ({
  type: 'APPLICATION_DATA_ERROR',
  error: error,
});

export const applicationInitialize = () => ({
  type: 'APPLICATION_INITIALIZE',
});

export const applicationLogOut = () => ({
  type: 'APPLICATION_LOG_OUT',
});

export const applicationChangeMode = (mode) => ({
  type: 'APPLICATION_CHANGE_MODE',
  mode: mode,
});
