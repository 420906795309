import React from 'react';
import {connect} from 'react-redux';
import DeliveryCard from "../Delivery/DeliveryCard";
import LoadingCard from "./LoadingCard";
import LoadingItemList from "./LoadingItemList";

class LoadingSelectionPanel extends React.Component {
  render() {
    const {installation, closePanel} = this.props;

    return (
      <div className={"LoadingSelectionPanel"}>
        <LoadingCard installation={installation}/>
        <LoadingItemList items={installation.items}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedInstallation = state.LoadingReducer.selectedInstallation;
  return {
    installation: state.LoadingReducer.installations[selectedInstallation],
  };
}

export default connect(mapStateToProps)(LoadingSelectionPanel);