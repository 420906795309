import React from 'react';
import {connect} from 'react-redux';
import {Button, FormGroup, TextArea} from "@blueprintjs/core";
import ReduxStore from "../../ReduxStore";
import {applicationSubmitData} from "../../Actions/ApplicationActions";


class DeliveryCompleteForm extends React.Component {
  state = {notes: ""};

  componentDidMount() {
    this.setState({
      notes: this.props.notes,
    })
  }

  handleCompletion = () => {
    this.props.closePanel();
    ReduxStore.dispatch(applicationSubmitData('delivery/complete', {
      loading: 'Completing Delivery...',
      data: {
        notes: this.state.notes,
        delivery: this.props.deliveryId,
      },
    }));
  };

  handleTextChange = (event) => {
    this.setState({notes: event.target.value});
  };

  render() {
    const {items} = this.props;
    const {notes} = this.state;

    let canComplete = true;
    items.forEach((item) => {
      if ( !item.location && !item.storageBay ) {
        canComplete = false;
      }
    });

    return (
      <div>
        <br/>
        <FormGroup
          label={<b>Delivery Notes</b>}
        >
          <TextArea
            small
            fill
            value={notes}
            onChange={this.handleTextChange}
            growVertically={true}
          />
        </FormGroup>
        <Button
          text={"Complete Delivery"}
          icon={"confirm"}
          intent={"success"}
          disabled={!canComplete}
          onClick={this.handleCompletion}
        />
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   return {};
// }

//export default connect(mapStateToProps)(DeliveryCompleteForm);
export default DeliveryCompleteForm;