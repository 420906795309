export const stagingSetSelection = (index) => ({
  type: 'STAGING_SET_SELECTION',
  index: index,
});

export const stagingSetItemSelection = (item) => ({
  type: 'STAGING_SET_ITEM_SELECTION',
  item: item,
});

export const stagingSetStorageFilter = (storageFilter) => ({
  type: 'STAGING_SET_STORAGE_FILTER',
  storageFilter: storageFilter,
});

export const stagingSetItemStorageBay = (bay) => ({
  type: 'STAGING_SET_ITEM_STORAGE',
  bay: bay,
});

export const stagingUpdateItems = (items) => ({
  type: 'STAGING_UPDATE_ITEMS',
  items: items,
});

export const stagingSetLocationFilter = (location) => ({
  type: 'STAGING_SET_LOCATION_FILTER',
  location: location,
});