import React from 'react';
import {connect} from 'react-redux';
import "./Delivery.css";
import {applicationChangeMode, applicationLoadData} from "../../Actions/ApplicationActions";
import DeliveryList from "./DeliveryList";
import DeliveryFilters from "./DeliveryFilters";
import {Button} from "@blueprintjs/core";
import ScannerPanel from "../Scanner/ScannerPanel";


class DeliveryPanel extends React.PureComponent {

  state = {barcode: 'NONE'};

  componentDidMount() {
    if ( !this.props.initialized ) {
      this.props.dispatch(applicationLoadData('delivery/load', {
        loading: 'Loading Deliveries...',
      }));
    }
  }

  startScanner = () => {
    this.props.dispatch(applicationChangeMode("scanner"));
    this.props.openPanel({
      component: ScannerPanel,
      title: "Delivery Scanner"
    });
  };


  render() {
    const {initialized, openPanel} = this.props;
    if ( !initialized ) return null;

    return (
      <div className={"DeliveryView"}>
        <DeliveryFilters/>

        <DeliveryList openPanel={openPanel} />
        <div className={"ScannerOpenButton"}>
          <Button
            fill
            intent={"primary"}
            icon={"barcode"}
            text={"Start Scanner"}
            onClick={this.startScanner}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.DeliveryReducer.initialized,
  };
}

export default connect(mapStateToProps)(DeliveryPanel);