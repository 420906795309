import React from 'react';
import {connect} from 'react-redux';
import {Button, Overlay} from "@blueprintjs/core";
import DeliveryStorageFilters from "./DeliveryStorageFilters";
import DeliveryStorageBays from "./DeliveryStorageBays";
import Grid from "@material-ui/core/Grid";
import {deliverySetItemStorageBay, deliveryUpdateItems} from "../../Actions/DeliveryActions";
import DataLoader from "../../Lib/Api/DataLoader";
import ReduxStore from "../../ReduxStore";

class DeliveryStorageSelection extends React.Component {



  static handleStorageSuccess(payload) {
    ReduxStore.dispatch(deliveryUpdateItems(payload));
  }


  render() {
    const {selectedItem, executingStorage} = this.props;

    let storageBays;
    if ( selectedItem === 'Other' ) {
      storageBays = null; // TODO: It should actually be the notes section!
    } else {
      storageBays = <DeliveryStorageBays/>;
    }
    //
    // const content;
    // if ( selectedItem ) {
    //   content = (
    //
    //   );
    // }

    return (
      <Overlay
        isOpen={selectedItem !== null}
        canOutsideClickClose={false}
        className={"DeliveryStorageSelection"}
      >
        <div className={"bp3-overlay-content"}>
          <DeliveryStorageFilters/>
          {storageBays}

        </div>
      </Overlay>
    );
  }
}

function mapStateToProps(state) {
  const selectedDeliveryId = state.DeliveryReducer.selectedDelivery;
  return {
    location: state.DeliveryReducer.deliveries[selectedDeliveryId].location,
    selectedItem: state.DeliveryReducer.selectedItem,
    executingStorage: state.DeliveryReducer.executingStorage,
  };
}

export default connect(mapStateToProps)(DeliveryStorageSelection);