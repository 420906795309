import React from 'react';
import Moment from "react-moment";


class UnixDate extends React.PureComponent {
  render() {
    const {timestamp, format} = this.props;

    return (
      <Moment local unix format={format || "M/DD/YYYY"}>{timestamp}</Moment>
    );
  }
}

export default UnixDate;