import React from 'react';
import {Typography} from "@material-ui/core";
import {Card} from "@blueprintjs/core";
import DeliveryCardTags from "../Delivery/DeliveryCardTags";
import ReduxStore from "../../ReduxStore";
import {loadingSetSelection} from "../../Actions/LoadingActions";
import LoadingSelectionPanel from "./LoadingSelectionPanel";
import LoadingCardTags from "./LoadingCardTags";

class LoadingCard extends React.Component {

  handleSelection = () => {
    const installation = this.props.installation;
    ReduxStore.dispatch(loadingSetSelection(this.props.index));
    this.props.openPanel({
      component: LoadingSelectionPanel,
      title: '#' + installation.inquiry + '-' + installation.id,
    });
  };

  render() {
    const {installation} = this.props;

    let notes;
    if ( this.props.openPanel && installation.notes ) {
      notes = (
        <Typography variant={"subtitle1"} className={"DeliveryCard-Notes"}>
          {installation.notes}
        </Typography>
      );
    }

    return (
      <Card
        interactive
        className={"Delivery-Card"}
        onClick={this.props.openPanel ? this.handleSelection : null}
      >
        <Typography>
          <b>#{installation.inquiry}-{installation.id}: {installation.customer}</b>
        </Typography>
        <LoadingCardTags installation={installation}/>
        {notes}
      </Card>
    );
  }
}

export default LoadingCard;