import React from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";
import {stagingSetStorageFilter} from "../../Actions/StagingActions";
import DeliveryStorageFiltersSouth from "../Delivery/DeliveryStorageFiltersSouth";
import DeliveryStorageFiltersNorth from "../Delivery/DeliveryStorageFiltersNorth";

class StagingStorageFilters extends React.Component {

  handleStorageFilter = (storageFilter) => () => {
    this.props.dispatch(stagingSetStorageFilter(storageFilter));
  };

  render() {
    const {storageFilter, executingStorage, location} = this.props;
    console.log(location);

    let defaultStorage = storageFilter;
    if ( location === "tukwila" ) {
      if ( !defaultStorage || defaultStorage === "Exterior" ) defaultStorage = "Windows";
      return (
        <DeliveryStorageFiltersSouth
          onClick={this.handleStorageFilter}
          executingStorage={executingStorage}
          storageFilter={defaultStorage}
        />
      )
    } else {
      return (
        <DeliveryStorageFiltersNorth
          onClick={this.handleStorageFilter}
          executingStorage={executingStorage}
          storageFilter={defaultStorage}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  const selectedInstallId = state.StagingReducer.selectedInstallation;
  return {
    location: state.StagingReducer.installations[selectedInstallId].location,
    storageFilter: state.StagingReducer.storageFilter,
    executingStorage: state.StagingReducer.executingStorage,
  };
}

export default connect(mapStateToProps)(StagingStorageFilters);