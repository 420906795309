import React from 'react';
import {Tag} from "@blueprintjs/core";

class StagingCardLocationTags extends React.Component {
  render() {
    const {items} = this.props;

    let tags = [];
    let locations = {};
    let storage = {};
    let truck = {};

    items.forEach((item) => {
      // Add the Location
      const location = item.location || 'shipping';
      if ( !locations[location] ) locations[location] = 1; else locations[location]++;

      if ( item.truck ) {
        if ( !truck[item.truck] ) truck[item.truck] = 1; else truck[item.truck]++;
      } else if ( item.storageBay && item.storageBay !== 'INVALID'  ) {
        if ( !storage[item.storageBay] ) storage[item.storageBay] = 1; else storage[item.storageBay]++;
      }



    });


    // Locations
    Object.keys(locations).forEach((locationKey) => {
      const locationClass = locationKey === 'shipping' ? 'bp3-intent-bling' : 'bp3-intent-data';
      tags.push(
        <Tag key={"location-" + locationKey} className={locationClass}>
          {locations[locationKey]}: {locationKey.charAt(0).toUpperCase() + locationKey.slice(1)}
        </Tag>
      );
    });

    // Truck
    Object.keys(truck).forEach((truckKey) => {
      tags.push(
        <Tag key={"truck-" + truckKey} className={"bp3-intent-data"}>
          {truck[truckKey]}: {truckKey.charAt(0).toUpperCase() + truckKey.slice(1)}
        </Tag>
      );
    });

    // Storage
    Object.keys(storage).forEach((storageKey) => {
      tags.push(
        <Tag key={"storage-" + storageKey} className={"bp3-intent-success"}>
          {storage[storageKey]}: {storageKey.charAt(0).toUpperCase() + storageKey.slice(1)}
        </Tag>
      );
    });


    return (
      <div className={"staging-location-tags"}>{tags}</div>
    );
  }
}

export default StagingCardLocationTags;