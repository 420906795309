import React from 'react';
import {connect} from 'react-redux';
import "./QuickView.css";
import UnixTime from "../../Lib/UnixTime/UnixTime";
import QuickViewRow from "./QuickViewRow";
import EmployeeName from "../Employee/EmployeeName";


class QuickView extends React.Component {
  render() {
    const {schedule, employees} = this.props;

    // Start and End of the Week
    const periodStart = UnixTime.getWeekStart();
    const periodEnd = periodStart + 14 * 86400;

    let rows = [];
    Object.keys(employees).forEach((id => {
      rows.push(
        <QuickViewRow
          key={id}
          employee={parseInt(id)}
          periodStart={periodStart}
          periodEnd={periodEnd}
        />
      );
    }));



    return (
      <div className="CQV">
        <div>QuickView</div><br/><br/>
        <div className="CQV-container">
          <div className="CQV-row row-header">
            <div className="CQV-row-employee"><b>Employee</b></div>
            <div className="CQV-grid grid-header">
              <div className="CQV-grid-week week-1">
                <div className="CQV-grid-day day-header">Monday</div>
                <div className="CQV-grid-day day-header">Tuesday</div>
                <div className="CQV-grid-day day-header">Wednesday</div>
                <div className="CQV-grid-day day-header">Thursday</div>
                <div className="CQV-grid-day day-header">Friday</div>
              </div>
              <div className="CQV-grid-week week-2">
                <div className="CQV-grid-day day-header">Monday</div>
                <div className="CQV-grid-day day-header">Tuesday</div>
                <div className="CQV-grid-day day-header">Wednesday</div>
                <div className="CQV-grid-day day-header">Thursday</div>
                <div className="CQV-grid-day day-header">Friday</div>
              </div>
            </div>
          </div>
          {rows}
          {/*<QuickViewRow*/}
          {/*  employee={6}*/}
          {/*  periodStart={periodStart}*/}
          {/*  periodEnd={periodEnd}*/}
          {/*/>*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    schedule: state.ApplicationReducer.system.schedule,
    employees: state.ApplicationReducer.system.employees,
  };
}

export default connect(mapStateToProps)(QuickView);