import DataLoader from "../Lib/Api/DataLoader";
import CloneDeep from "lodash/cloneDeep";

const DeliveryReducer = (state, action) => {
  switch ( action.type ) {

    case 'DELIVERIES_LOAD':
      DataLoader.loadData('deliveries/load', {});
      return Object.assign({}, state, {
        loading: 'Loading...',
      });

    case 'DELIVERIES_SET_TIME_FILTER':
      if ( action.timeFilter !== state.timeFilter ) {
        return Object.assign({}, state, {
          timeFilter: action.timeFilter,
        });
      } else return state;

    case 'DELIVERIES_SET_LOCATION_FILTER': {
      let filter = state.locationFilter.slice(0);
      const index = filter.indexOf(action.location);
      if (index === -1) filter.push(action.location);
      else if ( filter.length === 2 ) filter = ['OTHER', 'bothell', 'tukwila'];
      //else if ( filter.length > 2 ) filter.splice(index, 1);
      else filter = ['OTHER', action.location];
      return Object.assign({}, state, {
        locationFilter: filter,
      });
    }

    case 'DELIVERIES_SET_MANUFACTURER_FILTER': {
      let filter = state.manufacturerFilter.slice(0);
      const index = filter.indexOf(action.manufacturer);
      if (index === -1) filter.push(action.manufacturer);
      else if ( filter.length === 1 ) filter = ['Milgard', 'Simonton', 'Codel', 'Glass', 'Material', 'Other'];
      else filter = [action.manufacturer];
      return Object.assign({}, state, {
        manufacturerFilter: filter,
      });
    }

    case 'DELIVERIES_SET_STORAGE_FILTER':
      if ( state.storageFilter !== action.storageFilter ) {
        return Object.assign({}, state, {
          storageFilter: action.storageFilter,
        });
      } else return state;


    case 'DELIVERIES_SET_SELECTION':
      return Object.assign({}, state, {
        selectedDelivery: action.index,
      });

    case 'DELIVERIES_SET_ITEM_SELECTION':
      // TODO: We should set teh current storage filter to the item's storage filter.
      return Object.assign({}, state, {
        selectedItem: action.item,
        storageFilter: action.item && action.item.storage ? action.item.storage : state.storageFilter,
      });

    case 'DELIVERIES_SET_ITEM_STORAGE': {
      const item = Object.assign({}, state.selectedItem, {
        storage: action.bay,
      });
      return Object.assign({}, state, {
        executingStorage: action.bay,
        selectedItem: item,
      });
    }

    case 'DELIVERIES_UPDATE_ITEMS': {
      const deliveries = CloneDeep(state.deliveries);
      const delivery = deliveries[state.selectedDelivery];
      delivery.items = action.items;
      delivery.itemCount = action.items.length;
      return Object.assign({}, state, {
        deliveries: deliveries,
        executingStorage: false,
        selectedItem: null,
      });
    }

    case 'DELIVERIES_UPDATE_ITEMS_SPECIFIC': {
      const deliveries = CloneDeep(state.deliveries);
      const delivery = deliveries[action.delivery];
      delivery.items = action.items;
      delivery.itemCount = action.items.length;
      return Object.assign({}, state, {
        deliveries: deliveries,
        executingStorage: false,
        selectedItem: null,
      });
    }

    case 'APPLICATION_CHANGE_MODE': {
      return Object.assign({}, state, {
        initialized: false,
      });
    }



    case 'APPLICATION_UPDATE_STATE':
      const data = action.data;
      if ( data['@delivery'] ) {

        return Object.assign({}, state, data['@delivery']);
      } else return state;

    default:
      if ( state === undefined ) {
        return {
          initialized: false,
          loading: false,
          deliveries: [],
          storageBays: {},
          deliveriesKeyed: {},
          timeFilter: 'today',
          locationFilter: ['tukwila', 'bothell'],
          manufacturerFilter: ['Milgard', 'Simonton', 'Codel', 'Glass', 'Material', 'Other'],
          selectedDelivery: null,
          selectedItem: null,
          storageFilter: 'Exterior',
          executingStorage: false,
          //manufacturerFilter: ['Milgard', 'Glass'],
        }
      } else {
        return state;
      }
  }
};

export default DeliveryReducer;
