import React from 'react';
import {CircularProgress, Typography} from "@material-ui/core";
import {Colors} from "@blueprintjs/core";

class Loader extends React.Component {
  render() {
    const {message, suffix} = this.props;
    let messageStyle = this.props.messageStyle || {};
    let spinnerStyle = this.props.spinnerStyle || {};
    if ( this.props.textColor ) messageStyle.color = this.props.textColor;
    if ( this.props.color ) spinnerStyle.color = this.props.color;

    return (
      <div style={{textAlign: 'center', paddingTop: 50,}}>
        <CircularProgress style={spinnerStyle}/><br/>
        <Typography variant="subtitle1" style={messageStyle}>{message}</Typography><br/>
        {suffix === undefined && (<Typography variant="h1" style={{color: Colors.BLUE2}}>Quo360 App</Typography>)}
        {suffix}
      </div>
    )
  }
}

export default Loader;