import CloneDeep from "lodash/cloneDeep";

const LoadingReducer = (state, action) => {
  switch ( action.type ) {

    case 'LOADING_SET_SELECTION':
      return Object.assign({}, state, {
        selectedInstallation: action.index,
      });

    case 'LOADING_TOGGLE_ITEM':
      return Object.assign({}, state, {
        executing: action.item,
      });

    case 'LOADING_SET_MODE':
      return Object.assign({}, state, {
        mode: action.mode,
      });

    case 'LOADING_UPDATE_ITEMS': {
      const installations = CloneDeep(state.installations);
      const installation = installations[state.selectedInstallation];
      installation.items = action.items;
      installation.itemCount = action.items.length;
      return Object.assign({}, state, {
        installations: installations,
        executing: false,
      });
    }

    case 'APPLICATION_CHANGE_MODE': {
      return Object.assign({}, state, {
        initialized: false,
      });
    }

    case 'APPLICATION_UPDATE_STATE':
      const data = action.data;
      if ( data['@loading'] ) {

        return Object.assign({}, state, data['@loading']);
      } else return state;

    default:
      if ( state === undefined ) {
        return {
          initialized: false,
          loading: false,
          installations: [],
          executing: false,
          selectedInstallation: null,
          mode: 'today',
        }
      } else {
        return state;
      }
  }
};

export default LoadingReducer;
