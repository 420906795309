import React from 'react';
import {connect} from 'react-redux';
import {applicationLoadData} from "../../Actions/ApplicationActions";
import {Button, Callout, Icon, Overlay, ProgressBar, Tag} from "@blueprintjs/core";
import UIfx from "uifx";
import beepAudio from "../../Media/Audio/beep.mp3";
import errorAudio from "../../Media/Audio/error.mp3";
import DataLoader from "../../Lib/Api/DataLoader";
import DeliveryStorageFilters from "../Delivery/DeliveryStorageFilters";
import ScannerStorageBays from "./ScannerStorageBays";
import ScannerStorageFilters from "./ScannerStorageFilters";
import {scannerSetStorageFilter} from "../../Actions/ScannerActions";

// Beep Audio
const beep = new UIfx(beepAudio);
const error = new UIfx(errorAudio);

class ScannerPanel extends React.Component {
  currentBarcode = "";
  barcodeCounter = 0;
  state = {
    lastBarcode: "",
    error: "",
    storageBay: null,
    storageCode: null,
    storageDescription: "",
    itemStatus: {},
    itemList: [],
    storageOpen: false,
  };

  // Initialization Functions
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyInput);
    if ( !this.props.initialized ) {
      this.props.dispatch(applicationLoadData('scanner/info', {
        loading: 'Initializing Scanner',
      }));
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyInput);
  }

  // Primary Callback Function
  handleKeyInput = (e) => {
    if ( e.keyCode === 13 ) {
      const barcode = this.currentBarcode;
      this.currentBarcode = "";

      // Do nothing if this is the same barcode again!
      if ( barcode === this.state.lastBarcode ) {
        beep.play(1);
        return;
      }

      // Otherwise Process the Barcode
      this.handleBarCode(barcode);
    } else if ( e.key.length === 1 ) {
      this.currentBarcode += e.key;
    }
  };
  handleBarCode(barcode) {
    console.log("Handling Barcode... " + barcode);
    // Don't Duplicate Scans in Progress
    //const status = this.state.itemStatus;
    //if ( status[barcode] && status[barcode] === 'Saving' ) return;
    //TODO: Refix Statuses...
  
    barcode = barcode.replace('URL:', '');
    
    // Handle based on Prefix
    const prefix = barcode.substr(0, 2);
    if ( prefix === 'SB' ) {
      this.handleStorageBayCode(barcode);
    } else if ( barcode && prefix === 'WI' ) {
      if ( this.state.storageBay ) {
        this.handleWorkItemCode(barcode);
      } else {
        error.play(1);
        this.setState({
          error: "Scan or manually select a storage bay before scanning items.",
          lastBarcode: barcode,
        });
      }
    } else {
      error.play(1);
      this.setState({
        error: "Invalid Barcode",
        lastBarcode: barcode,
      });
    }
  };

  // Storage Bay Functions
  handleStorageBayCode(barcode, playSound = true) {
    const storageDescription = ScannerPanel.getStorageDescription(barcode);
    const storageCodes = barcode.split('-');
    const bayCode = storageCodes[2] + "-" + storageCodes[3];
    if ( storageDescription ) {
      if ( playSound ) beep.play(1);
      this.setState({
        storageBay: barcode,
        storageCode: bayCode,
        lastBarcode: barcode,
        storageOpen: false,
        storageDescription: storageDescription,
        itemList: [],
        itemStatus: {},
        error: "",
      });

      const storageFilterKey = ScannerPanel.getStorageKey(storageCodes[2]);
      this.props.dispatch(scannerSetStorageFilter(storageFilterKey))

    } else {
      if ( playSound ) error.play(1);
      this.setState({
        error: "Invalid Storage Bay Barcode",
        storageBay: null,
        storageCode: null,
        storageDescription: "",
        storageOpen: false,
        lastBarcode: barcode,
        itemList: [],
        itemStatus: {},
      });
    }
  }
  static getStorageDescription(barcode) {
    const codes = barcode.split('-');
    const location = ScannerPanel.getStorageLocation(codes[1]);
    const storage = ScannerPanel.getStorageName(codes[2]);
    const bay = parseInt(codes[3]);
    return location + ': ' + storage + ' #' + bay.toString().padStart();
  }
  static getStorageLocation(location) {
    switch ( location ) {
      case 'N': return 'Bothell';
      case 'S': return 'Tukwila';
      default: return 'Unknown';
    }
  }
  static getStorageName(name) {
    switch ( name ) {
      case 'SHP': return 'Shop';
      case 'GRG': return 'Garage';
      case 'EXT': return 'Exterior';
      case 'SRV': return 'Service Rack';
      case 'PRT': return 'Parts Rack';
      case 'LRG': return 'LG Lumber Rack';
      case 'SMR': return 'SM Lumber Rack';
      case 'INT': return 'IN LMBR Rack';
      case 'CON': return 'Consumables';
      case 'WIN': return "Window Storage";
      case 'DOR': return "Door Storage";
      case 'GLS': return "Glass Storage";
      case 'OVR': return "Overflow";
      case 'RTR': return "Returns";
      case 'MAT': return "Materials";
      default: return 'Unknown';
    }
  }
  static getStorageKey(name) {
    switch ( name ) {
      case 'SHP': return 'Shop';
      case 'GRG': return 'Garage';
      case 'EXT': return 'Exterior';
      case 'SRV': return 'Service';
      case 'PRT': return 'Parts';
      case 'LRG': return 'LG Rack';
      case 'SMR': return 'SM Rack';
      case 'INT': return 'INT Rack';
      case 'CON': return 'Consume';
      case 'WIN': return "Windows";
      case 'DOR': return "Doors";
      case 'GLS': return "Glass";
      case 'OVR': return "Overflow";
      case 'RTR': return "Returns";
      case 'MAT': return "Material";
      default: return 'Unknown';
    }
  }
  static clearItemDuplicates(itemList, barcode) {
    return itemList.filter((item) => {
      return item.barcode !== barcode;
    });
  }

  handleWorkItemCode(barcode) {
    // Gather Barcode Meta
    const codes = barcode.split('-');
    const itemId = parseInt(codes[2]);
    const storageBay = this.state.storageBay;
    const storageCodes = storageBay.split('-');
    const location = storageCodes[1];
    const storage = storageCodes[2];
    const bay = parseInt(storageCodes[3]);

    // Update the Barcode Status and List
    const itemList = ScannerPanel.clearItemDuplicates(this.state.itemList, barcode);
    if ( itemList.length === 9 ) itemList.shift();

    // Add the New Item to the Status List
    this.barcodeCounter++;
    itemList.push({
      barcode: barcode,
      status: 'Saving',
      index: this.barcodeCounter,
    });

    // Update the State
    beep.play(1);
    this.setState({
      error: "",
      lastBarcode: barcode,
      itemList: itemList,
    });

    // Submit The Data
    DataLoader.submitData('scanner/save', {
      onSuccess: this.handleStorageSuccess,
      onError: this.handleStorageError,
      data: {
        item: itemId,
        storage: storage,
        storageBay: bay,
        location: location,
        index: this.barcodeCounter,
      }
    });
  }
  handleStorageSuccess = (payload) => {
    console.log("Storage Success");
    console.log(payload);
    if ( payload && payload.index ) {
      const itemList = this.state.itemList;
      itemList.forEach((item, index) => {
        if ( item.index === payload.index ) {
          item.status = 'Saved';
          item.title = payload.title;
          item.position = payload.position;
          item.inquiry = payload.inquiry;
          this.setState({itemList: itemList});
        }
      });
    }
  };
  handleStorageError = (error, options) => {
    const errorIndex = options.data.index;
    const itemList = this.state.itemList;
    itemList.forEach((item, index) => {
      if ( item.index === errorIndex ) {
        item.status = 'Error';
        item.error = error.message;
        this.setState({itemList: itemList});
      }
    });
  };

  handleOpenStorageSelection = () => {
    this.setState({storageOpen: !this.state.storageOpen});
  };

  handleManualBaySelection = (bay) => {
    this.handleStorageBayCode(bay, false);
  };

  render() {
    const {lastBarcode, error, storageBay, storageDescription, itemList, storageOpen, storageCode} = this.state;

    // Barcode Icon
    const barcodeIcon = lastBarcode ? <Icon icon={"barcode"}/> : null;

    // Build Error Message
    let errorCallout;
    if ( error ) {
      errorCallout = (
        <Callout
          intent={"danger"}
          style={{marginTop: 10, marginBottom: 10}}
        >{error}</Callout>
      )
    }

    // Build teh Item List
    const items = [];
    itemList.forEach((item) => {

      // Determine the Callout Information
      const itemTitle = item.title || item.barcode;
      let icon;
      let intent = "primary";
      let status = null;
      let progress;
      let label;
      switch ( item.status ) {
        case 'Saving':
          intent = "primary";
          icon = (<Icon icon={"refresh"} iconSize={14}/>);
          status = (<ProgressBar intent={"success"}/>);
          break;

        case 'Saved':
          icon = (<Icon icon={"tick"} iconSize={14}/>);
          intent = "success";
          label = (
            <>
              <Tag className={"DeliveryItemCard-Tag-Inquiry bp3-intent-primary"}>{item.inquiry}</Tag>{" "}
              <Tag className={"DeliveryItemCard-Tag-Position"}>
                #{item.position.toString().padStart(3, '0')}
              </Tag>
            </>
          );
          break;

        default:
          intent = "danger";
          icon = (<Icon icon={"error"} iconSize={14}/>);
          status = item.error || "An Unknown Error Occurred.";
          break;
      }


      items.push(
        <Callout
          key={item.index}
          icon={null}
          intent={intent}
          className={"ScannerView-Item"}
        >
          <div>{icon} {label} <b>{itemTitle}</b></div>
          {status}

        </Callout>
      );
    });



    return (
      <div className={"ScannerView"}>
        <Callout
          title={"Barcode Scanner"}
          intent={"primary"}
          style={{marginBottom: 5}}
        >Scan a Storage Bay Code or manually select a storage bay, then scan any number of products to save those
          products into the selected storage bay.</Callout>
        <Button
          // intent={"default"}
          fill
          text={storageBay ? storageDescription : "Manually Select Storage Bay"}
          icon={"heat-grid"}
          intent={storageBay ? "success" : "danger"}
          onClick={this.handleOpenStorageSelection}
        />
        <h3 style={{marginBottom: 0}}>Scanning...</h3>
        <div>{barcodeIcon} {lastBarcode}</div>
        {errorCallout}
        {items}

        <Overlay
          isOpen={storageOpen}
          canOutsideClickClose={false}
          className={"DeliveryStorageSelection"}
        >
          <div className={"bp3-overlay-content"}>
            <ScannerStorageFilters/>
            <ScannerStorageBays
              currentBay={storageCode}
              onSelection={this.handleManualBaySelection}
              onCancel={this.handleOpenStorageSelection}
            />
          </div>
        </Overlay>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.ScannerReducer.initialized,
  };
}

export default connect(mapStateToProps)(ScannerPanel);