import React from 'react';
import {connect} from 'react-redux';
import DeliveryCard from "./DeliveryCard";
import UnixTime from "../../Lib/UnixTime/UnixTime";

class DeliveryList extends React.Component {

  static isTimeValid(timeFilter, deliveryDate, today) {
    return (
      (timeFilter === 'today' && deliveryDate === today)
      || (timeFilter === 'future' && deliveryDate > today)
      || (timeFilter === 'past' && deliveryDate < today)
    );
  }

  static isManufacturerValid(manufacturerFilter, manufacturers) {
    for ( var i = 0; i < manufacturerFilter.length; i++ ) {
      const filter = manufacturerFilter[i];
      if ( manufacturers[filter] ) return true;
    }

    // Return False
    return false;
  }

  render() {
    const {deliveries, timeFilter, locationFilter, manufacturerFilter, openPanel } = this.props;
    const today = UnixTime.getTodayStart();


    let cards = [];
    //Object.keys(deliveries).forEach((deliveryId) => {
    deliveries.forEach((delivery, index) => {
      //const delivery = deliveries[deliveryId];

      const isTimeValid = DeliveryList.isTimeValid(timeFilter, delivery.deliveryDate, today);
      const isManufacturerValid = DeliveryList.isManufacturerValid(manufacturerFilter, delivery.manufacturers);
      const isLocationValid = locationFilter.indexOf(delivery.location) !== -1;


      if ( isTimeValid && isLocationValid && isManufacturerValid ) {
        cards.push(
          <DeliveryCard
            key={delivery.id}
            index={index}
            delivery={delivery}
            openPanel={openPanel}
          />
        );
      }
    });

    return (
      <div className={"Delivery-List"}>
        {cards}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveries: state.DeliveryReducer.deliveries,
    timeFilter: state.DeliveryReducer.timeFilter,
    locationFilter: state.DeliveryReducer.locationFilter,
    manufacturerFilter: state.DeliveryReducer.manufacturerFilter,
  };
}

export default connect(mapStateToProps)(DeliveryList);