import React from 'react';
import {connect} from 'react-redux';
import {Button} from "@blueprintjs/core";
import TimeClockPanel from "./TimeClockPanel";
import ScheduleView from "../Schedule/ScheduleView";
import ClockInPanel from "./ClockInPanel";
import ReduxStore from "../../ReduxStore";
import {applicationSubmitData} from "../../Actions/ApplicationActions";
import StartLunchPanel from "./StartLunchPanel";
import ClockOutPanel from "./ClockOutPanel";

class ClockOutForm extends React.PureComponent {


  startNextJob = () => {
    this.props.openPanel({
      component: ClockInPanel,
      props: { enabled: true },
      title: 'Next Job',
    });
  };

  handleLunchStart = () => {
    this.props.openPanel({
      component: StartLunchPanel,
      props: { lunch: this.props.lunch },
      title: this.props.lunch ? 'Resume Clock' : 'Pause Clock',
    });
  };

  handleClockOut = () => {
    this.props.openPanel({
      component: ClockOutPanel,
      title: 'Clock Out',
    });
  };

  render() {
    const {clockedIn, lunch} = this.props;
    if ( !clockedIn ) return null;

    return (
      <div>
        <div className="app-list">
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="confirm"
              text="Next Job"
              large={true}
              intent="primary"
              onClick={this.startNextJob}
              //disabled={lunch}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon={lunch ? "play" : "pause"}
              text={lunch ? "Resume" : "Pause"}
              large={true}
              intent={lunch ? "success" : "warning"}
              onClick={this.handleLunchStart}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="log-out"
              text="Clock Out"
              large={true}
              onClick={this.handleClockOut}
              intent={"danger"}
              //disabled={lunch}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clockedIn: state.TimeClockReducer.clockedIn,
    lunch: state.TimeClockReducer.lunch,
  };
}

export default connect(mapStateToProps)(ClockOutForm);