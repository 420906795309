import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";

class DeliveryStorageFiltersNorth extends React.Component {
  render() {
    const {onClick, storageFilter, executingStorage} = this.props;

    return (
      <div className={"DeliveryStorageFilters"}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Button
              fill
              text={"Exterior"}
              intent={storageFilter === 'Exterior' ? 'primary' : null}
              onClick={onClick('Exterior')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fill
              text={"Garage"}
              intent={storageFilter === 'Garage' ? 'primary' : null}
              onClick={onClick('Garage')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fill
              text={"Shop"}
              onClick={onClick('Shop')}
              intent={storageFilter === 'Shop' ? 'primary' : null}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fill
              text={"Service"}
              onClick={onClick('Service')}
              intent={storageFilter === 'Service' ? 'primary' : null}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fill
              text={"Parts"}
              onClick={onClick('Parts')}
              intent={storageFilter === 'Parts' ? 'primary' : null}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fill
              text={"LG Rack"}
              intent={storageFilter === 'LG Rack' ? 'primary' : null}
              onClick={onClick('LG Rack')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fill
              text={"SM Rack"}
              intent={storageFilter === 'SM Rack' ? 'primary' : null}
              onClick={onClick('SM Rack')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fill
              text={"INT Rack"}
              intent={storageFilter === 'INT Rack' ? 'primary' : null}
              onClick={onClick('INT Rack')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fill
              text={"Consume"}
              intent={storageFilter === 'Consume' ? 'primary' : null}
              onClick={onClick('Consume')}
              disabled={!!executingStorage}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default DeliveryStorageFiltersNorth;