import React from 'react';
import {connect} from 'react-redux';
import Schedule from "../../Lib/Schedule/Schedule";
import UnixTime from "../../Lib/UnixTime/UnixTime";
import {Button, Callout, Card, Icon, InputGroup} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";
import UnixDate from "../UnixDate/UnixDate";
import TimeClockPanel from "./TimeClockPanel";
import ScheduleView from "../Schedule/ScheduleView";
import ReduxStore from "../../ReduxStore";
import {timeClockUpdate} from "../../Actions/TimeClockActions";

class ClockJobSelection extends React.Component {
  state = {
    mode: 'work',
    selectedJob: null,
    expandList: false,
    manual: false,
    search: '',
  };

  // Switch Mode
  switchMode = (mode) => () => {
    this.setState({
      mode: this.state.mode === mode ? null : mode,
    })
  };

  // Expand Job List
  expandList = () => {
    this.setState({
      search: '',
      expandList: true,
      selectedJob: null,
      manual: false,
    });
  };

  handleSearch = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  // Job Selection
  selectJob = (id) => () => {
    this.setState({
      expandList: false,
      selectedJob: id,
      manual: true,
      search: '',
    });
  };

  static searchValid(search, item) {
    // blank search...
    if ( search === "" ) return true;
    const searchLower = search.toLowerCase();
    return item.customer.toLowerCase().includes(searchLower)
      || item.inquiry.toString().includes(searchLower);
  }

  confirmClock = () => {
    const info = {
      workorder: this.state.selectedJob,
      mode: this.state.mode,
    };
    this.props.confirmJobClock(info);
    // // TODO: Send the Command to Quo360
    // ReduxStore.dispatch(timeClockUpdate({
    //   clockedIn: true,
    //   sessionStart: UnixTime.getNow(),
    // }));
    // this.props.closePanel();
  };

  render() {
    const {schedule} = this.props;
    const {mode, expandList, manual, search} = this.state;

    // Get the Schedule
    // TODO: Items need to be restricted to a single entry per job. (We shouldn't use the Quick Schedule Feature at all...
    const startTime = UnixTime.getTodayStart();
    const endTime = startTime + (expandList ? 259200 : 84600); // 3 Days
    const employeeId = manual || expandList ? null : this.props.employeeId;
    const items = Schedule.getEmployeeQuickSchedule(schedule, employeeId, startTime, endTime).sort((a,b) => {
      if ( a.start === b.start ) return 0;
      else return a.start > b.start ? 1 : -1;
    });

    // Determine the Selected Job
    let selectedJob = null;
    if ( this.state.selectedJob ) selectedJob = this.state.selectedJob;
    else if ( !this.state.selectedJob && !expandList && items[0] ) selectedJob = items[0].id;
    if ( this.state.selectedJob !== selectedJob ) {
      this.setState({selectedJob: selectedJob});
      return null;
    }

    // Build the Job Selection List
    let list = [];
    items.forEach((item, index) => {
      if ( (manual && list.length > 0) || (selectedJob && item.id != selectedJob) ) return;

      // Determine Icon
      let icon;
      if ( item.type === 'install' ) icon = (<Icon icon="build" />);
      else icon = (<Icon icon="search" />);

      // Determine Card Class
      let cardClass = "scheduleCard";
      if ( selectedJob === item.id ) cardClass += " bp3-intent-primary";

      // Determine rather or not to display Date
      let dateDisplay;
      if ( expandList ) dateDisplay = (<UnixDate timestamp={item.start}/>);

      // Now build Time Display
      let timeDisplay;
      if ( !manual ) {
        timeDisplay = (
          <Typography variant="body2">
            <b>{dateDisplay} <UnixDate timestamp={item.start} format="h:mm A"/> - <UnixDate timestamp={item.end} format="h:mm A"/></b>
          </Typography>
        );
      }


      if ( !expandList || ClockJobSelection.searchValid(search, item) ) {
        list.push(
          <Card
            elevation={1}
            className={cardClass}
            key={item.id + "-" + index}
            interactive={expandList}
            onClick={expandList ? this.selectJob(item.id) : null}
          >
            <Typography component="h5">
              {icon} <b>#{item.inquiry}-{item.id}: {item.title}</b>
            </Typography>
            <Typography variant="body2">
              {item.customer}<br/>
              {item.address}
            </Typography>
            {timeDisplay}
          </Card>
        );
      }

    });

    // Error if there are no Items
    let noJobsMessage;
    if ( !selectedJob && !expandList ) {
      noJobsMessage = (
        <Callout intent={"warning"} title={"No Scheduled Appointments"} style={{marginBottom: '2vw'}}>
          You have no scheduled appointments for the day. If this is in error, use the button below to search for a job.
        </Callout>
      );
    }


    // Build the Job Clock-In Method
    let jobMode = "";
    if ( false && selectedJob ) {
      jobMode = (
        <div className="app-list">
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="box"
              text="Load"
              large={true}
              intent={mode === 'load' ? 'primary' : null}
              onClick={this.switchMode('load')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="drive-time"
              text="Drive"
              large={true}
              intent={mode === 'drive' ? 'primary' : null}
              onClick={this.switchMode('drive')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="build"
              text="Work"
              large={true}
              intent={mode === 'work' ? 'primary' : null}
              onClick={this.switchMode('work')}
            />
          </div>
        </div>
      );
    }


    // Find Job Button
    let expandButton;
    if ( !expandList ) {
      expandButton = (
        <Button
          text="Find Another Job"
          icon="search"
          style={{marginBottom: '2vw'}}
          onClick={this.expandList}
        />
      );
    }

    // Search Box
    let searchBox;
    if ( expandList ) {
      searchBox = (
        <div style={{marginBottom: '2vw'}}>
          <InputGroup
            leftIcon="search"
            value={this.state.search}
            placeholder={"Search by Job Id/Customer"}
            onChange={this.handleSearch}
          >

          </InputGroup>
        </div>
      );
    }

    let confirmButton;
    if ( selectedJob ) {
      confirmButton = (
        <Button
          icon="confirm"
          text="Confirm Clock-In"
          intent="success"
          fill={true}
          disabled={!mode}
          onClick={this.confirmClock}
        />
      );
    }


    return (
      <div className="ClockJobSelection">
        {noJobsMessage}
        {expandButton}
        {searchBox}
        {list}
        {jobMode}
        {confirmButton}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeId: state.ApplicationReducer.system.activeEmployee,
    schedule: state.ApplicationReducer.system.schedule,
  };
}

export default connect(mapStateToProps)(ClockJobSelection);