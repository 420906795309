import React from 'react';
import UnixDate from "../UnixDate/UnixDate";
import {Typography} from "@material-ui/core";

class TimeClockTicker extends React.PureComponent {
  render() {
    const {title} = this.props;

    let time = this.props.time;
    let hours = Math.floor(time / 3600); time -= hours * 3600;
    let minutes = Math.floor(time / 60); time -= minutes * 60;
    if ( hours < 10 ) hours = '0' + hours;
    if ( minutes < 10 ) minutes = '0' + minutes;
    if ( time < 10 ) time = '0' + time;



    return (
      <>
        <Typography className="TimeClock-Ticker" variant="h1" align="center">
          {/*<UnixDate timestamp={time} format={"hh : mm : ss"}/>*/}
          {hours} : {minutes} : {time}
        </Typography>
        <div className="TimeClock-Ticker-Help">
          <Typography variant="caption">Hours</Typography>
          <Typography variant="caption">Minutes</Typography>
          <Typography variant="caption">Seconds</Typography>
        </div>
      </>
    );
  }
}

export default TimeClockTicker;
