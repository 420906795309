import React from 'react';
import "./Schedule.css";
import {Button, Tab, Tabs} from "@blueprintjs/core";
import ScheduleDateSelect from "./ScheduleDateSelect";
import {connect} from "react-redux";
import ScheduleList from "./ScheduleList";
import Schedule from "../../Lib/Schedule/Schedule";
import UnixTime from "../../Lib/UnixTime/UnixTime";
import ScheduleMap from "./ScheduleMap";
import Grid from "@material-ui/core/Grid";

class ScheduleView extends React.Component {
  state = {
    date: null,
    selectDate: false,
    adminMode: false,
  };

  handleDateToggle = () => {
    this.setState({selectDate: !this.state.selectDate});
  };

  handleDateSelect = (date) => {
    if ( date ) {
      this.setState({date: date});
    }
  };

  handleAdminToggle = () => {
    this.setState({adminMode: !this.state.adminMode});
  };


  render() {
    const {schedule, activeEmployee, activeMembers, permission} = this.props;
    const { date, selectDate, adminMode } = this.state;

    // Determine if the user has admin permissions.
    const adminAccess = permission.indexOf('fullSchedule') > -1;

    let adminButton = '';
    if ( adminAccess ) adminButton = (
      <Button
        icon={adminMode ? "user" : "people"}
        text={adminMode ? "My Schedule" : "Full Schedule"}
        intent={"primary"}
        onClick={this.handleAdminToggle}
      />
    );


    // Initialize Display
    let scheduleDisplay = '';

    // Calendar Select
    const timestamp = date ? UnixTime.getDateTimestamp(date) : UnixTime.getTodayStart();
    if ( !adminMode ) {
      const installations = Schedule.getDaySchedule(schedule, timestamp, null, activeEmployee, activeMembers);
      scheduleDisplay = (
        <>
          <ScheduleList schedule={installations}/>
          <ScheduleMap  schedule={installations}/>
        </>
      );
    } else {
      const installations = Schedule.getDaySchedule(schedule, timestamp,'install');
      const inspections = Schedule.getDaySchedule(schedule, timestamp,'inspect');
      scheduleDisplay = (
        <Tabs id="ScheduleTodayTabs" large>
          <Tab
            id="install"
            title="Installations"
            panel={
              <>
                <ScheduleList schedule={installations}/>
                <ScheduleMap  schedule={installations}/>
              </>
            }
          />
          <Tab
            id="inspect"
            title="Inspections"
            panel={
              <>
                <ScheduleList schedule={inspections}/>
                <ScheduleMap schedule={inspections}/>
              </>
            }
          />
        </Tabs>
      );
    }





    return (
      <div className="scheduleView">
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {adminButton}{" "}
            <Button
              icon="timeline-events"
              text={selectDate ? "Hide Date" : "Select Date"}
              intent={selectDate ? "danger" : "success"}
              onClick={this.handleDateToggle}
            />{" "}

            <Button
              icon="refresh"
              text="Refresh"
              disabled={true}
              //intent="primary"
            />
          </Grid>
        </Grid>

        <br/>

        <ScheduleDateSelect
          date={date}
          timestamp={timestamp}
          onChange={this.handleDateSelect}
          visible={selectDate}
        />

        {scheduleDisplay}
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    schedule: state.ApplicationReducer.system.schedule,
    activeEmployee: state.ApplicationReducer.system.activeEmployee,
    activeMembers: state.ApplicationReducer.system.activeMembers,
    permission: state.ApplicationReducer.system.permission,
  };
}

export default connect(mapStateToProps)(ScheduleView);
