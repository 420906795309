import React from 'react';
import isEqual from "react-fast-compare";
import {DatePicker} from "@blueprintjs/datetime";
import UnixTime from "../../Lib/UnixTime/UnixTime";

class ScheduleDateSelect extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { date, timestamp, onChange, visible } = this.props;
    if ( !visible ) return null;

    const maxDate = UnixTime.getTimestampDate(timestamp + 28 * 86400);
    const minDate = UnixTime.getTimestampDate(timestamp - 21 * 86400);

    return (
      <div>
        <DatePicker
          onChange={onChange}
          defaultValue={date ? date : UnixTime.getDateToday()}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    )
  }
}

export default ScheduleDateSelect;
