import React from 'react';
import {connect} from 'react-redux';
import DeliveryStorageFiltersSouth from "../Delivery/DeliveryStorageFiltersSouth";
import DeliveryStorageFiltersNorth from "../Delivery/DeliveryStorageFiltersNorth";
import {scannerSetStorageFilter} from "../../Actions/ScannerActions";

class ScannerStorageFilters extends React.Component {
  handleStorageFilter = (storageFilter) => () => {
    this.props.dispatch(scannerSetStorageFilter(storageFilter));
  };

  render() {
    const {storageFilter, executingStorage, location} = this.props;

    if ( location === "tukwila" ) {
      return (
        <DeliveryStorageFiltersSouth
          onClick={this.handleStorageFilter}
          executingStorage={executingStorage}
          storageFilter={storageFilter}
        />
      )
    } else {
      return (
        <DeliveryStorageFiltersNorth
          onClick={this.handleStorageFilter}
          executingStorage={executingStorage}
          storageFilter={storageFilter}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    location: state.ScannerReducer.location,
    storageBays: state.ScannerReducer.storageBays,
    storageFilter: state.ScannerReducer.storageFilter,
  };
}

export default connect(mapStateToProps)(ScannerStorageFilters);