import React from 'react';
import LoadingItemCard from "./LoadingItemCard";

class LoadingItemList extends React.Component {
  render() {

    const {items} = this.props;

    let cards = [];
    items.forEach((item, index) => {
      cards.push(
        <LoadingItemCard key={item.id} item={item} index={index}/>
      );
    });

    return (
      <div className={"DeliveryItemList"}>
        {cards}
      </div>
    )
  }
}

export default LoadingItemList;