import React from 'react';
import DeliveryItemCard from "./DeliveryItemCard";

class DeliveryItemList extends React.Component {
  render() {
    const {items} = this.props;

    let cards = [];
    // Unreceived First
    items.forEach((item, index) => {
      //if ( !item.location ) cards.push(
      cards.push(
        <DeliveryItemCard key={item.id} item={item} index={index}/>
      );
      //);
    });

    // Then Received
    // items.forEach((item, index) => {
    //   if ( item.location ) cards.push(
    //     <DeliveryItemCard key={item.id} item={item} index={index}/>
    //   );
    // });

    return (
      <div className={"DeliveryItemList"}>
        {cards}
      </div>
    );
  }
}

export default DeliveryItemList;