import React from 'react';
import {Typography} from "@material-ui/core";
import {Button} from "@blueprintjs/core";
import {geolocated} from "react-geolocated";
import {applicationSubmitData} from "../../Actions/ApplicationActions";
import ReduxStore from "../../ReduxStore";

class StartLunchPanel extends React.Component {

  handleStartLunch = () => {
    // Base Data
    const data = {
      type: 'lunch',
    };

    // GPS Coordinates
    if ( this.props.isGeolocationAvailable && this.props.coords ) {
      data.lat = this.props.coords.latitude;
      data.lng = this.props.coords.longitude;
    }

    // Start Lunch
    ReduxStore.dispatch(applicationSubmitData('timelog/start', {
      onSuccess: this.handleSubmitSuccess,
      loading: 'Starting Break...',
      data: data,
    }));
  };

  handleEndLunch = () => {
    // Base Data
    const data = {
      type: 'lunch',
    };

    // GPS Coordinates
    if ( this.props.isGeolocationAvailable && this.props.coords ) {
      data.lat = this.props.coords.latitude;
      data.lng = this.props.coords.longitude;
    }

    // Start Lunch
    ReduxStore.dispatch(applicationSubmitData('timelog/resume', {
      onSuccess: this.handleSubmitSuccess,
      loading: 'Resuming Work...',
      data: data,
    }));
  };

  handleSubmitSuccess = (payload) => {
    if ( payload === 'Success' ) {
      this.props.closePanel();
    }
  };

  render() {
    const {lunch} = this.props;

    // Long Text
    let text;
    let help;
    if ( !lunch ) {
      text = "Pause Time Clock?";
      help = "Use this function to take a lunch or unpaid break from your current job.";
    } else {
      text = "Resume Time Clock?";
      help = "Use this function if you are returning to your previous appointment. If you are going to start work on a new appointment, select \"Next Job\" instead. If you are done for the day, select \"Clock Out\" instead.";
    }

    // Geo Coordinates
    let geoStatus;
    if ( !this.props.isGeolocationAvailable ) {
      return (<div>GPS Unavailable. You must enable GPS on your device to use the time clock.</div>);
    } else if ( !this.props.isGeolocationEnabled ) {
      return (<div>GPS Disabled. You must enable GPS on your device to use the time clock.</div>);
    } else if ( this.props.coords ) {
      geoStatus = (<div>GPS Coordinates: {this.props.coords.latitude.toFixed(6)}, {this.props.coords.longitude.toFixed(6)}</div>);
    } else {
      geoStatus = (<div>GPS Status Unknown</div>);
    }

    return (
      <div className="StartLunchPanel">
        <Typography variant="h5">
          {text}
        </Typography>
        <br/>
        <Typography>
          {help}
        </Typography>
        <br/>
        <Button
          fill
          text={lunch ? "Resume Work" : "Pause Time Clock"}
          icon={lunch ? "play" : "pause"}
          intent={lunch ? "success" : "warning"}
          onClick={lunch ? this.handleEndLunch : this.handleStartLunch}
        />
        <small>{geoStatus}</small>
      </div>
    );
  }
}

//export default StartLunchPanel;
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  watchPosition: true,
  userDecisionTimeout: 5000,
})(StartLunchPanel);