export const deliveriesLoad = () => ({
  type: 'DELIVERIES_LOAD',
});

export const deliverySetTimeFilter = (timeFilter) => ({
  type: 'DELIVERIES_SET_TIME_FILTER',
  timeFilter: timeFilter,
});

export const deliverySetLocationFilter = (location) => ({
  type: 'DELIVERIES_SET_LOCATION_FILTER',
  location: location,
});

export const deliverySetStorageFilter = (storageFilter) => ({
  type: 'DELIVERIES_SET_STORAGE_FILTER',
  storageFilter: storageFilter,
});

export const deliverySetManufacturerFilter = (manufacturer) => ({
  type: 'DELIVERIES_SET_MANUFACTURER_FILTER',
  manufacturer: manufacturer,
});

export const deliverySetSelection = (index) => ({
  type: 'DELIVERIES_SET_SELECTION',
  index: index,
});

export const deliverySetItemSelection = (item) => ({
  type: 'DELIVERIES_SET_ITEM_SELECTION',
  item: item,
});

export const deliverySetItemStorageBay = (bay) => ({
  type: 'DELIVERIES_SET_ITEM_STORAGE',
  bay: bay,
});

export const deliveryUpdateItems = (items) => ({
  type: 'DELIVERIES_UPDATE_ITEMS',
  items: items,
});

export const deliveryUpdateItemsSpecific = (delivery, items) => ({
  type: 'DELIVERIES_UPDATE_ITEMS_SPECIFIC',
  delivery: delivery,
  items: items,
});