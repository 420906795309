import React from 'react';
import StagingItemCard from "./StagingItemCard";

class StagingItemList extends React.Component {
  render() {
    const {items} = this.props;

    let cards = [];
    items.forEach((item, index) => {
      cards.push(
        <StagingItemCard key={item.id} item={item} index={index}/>
      );
    });

    return (
      <div className={"DeliveryItemList"}>
        {cards}
      </div>
    );
  }
}

export default StagingItemList;