import React from 'react';

class KeyButton extends React.PureComponent {
  render() {
    const {primary, secondary, clear, enter, disabled, onClick} = this.props;
    let classname = "keypad-button";
    if ( clear ) classname += " keypad-button-clear";
    if ( enter ) classname += " keypad-button-enter";
    if ( disabled ) classname += " keypad-button-disabled";
    return (
      <div className={classname} onClick={!disabled ? onClick : null}>
        <div className="keypad-button-primary">{primary}</div>
        <div className="keypad-button-secondary">{secondary}</div>
      </div>
    )
  }
}

export default KeyButton;
