import React from 'react';
import {Button, Card, Icon, Tag} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";
import DataLoader from "../../Lib/Api/DataLoader";
import {connect} from "react-redux";
import {loadingToggleItem, loadingUpdateItems} from "../../Actions/LoadingActions";

class LoadingItemCard extends React.Component {
  state = {expanded: false, loading: false};

  handleToggle = () => {
    this.setState({expanded: !this.state.expanded});
  };

  handleItemSelection = () => {
    const installation = this.props.installation;
    this.props.dispatch(loadingToggleItem(this.props.item.id));
    DataLoader.submitData('loading/toggle', {
      onSuccess: this.handleLoadSuccess,
      data: {
        item: this.props.item.id,
        installation: installation.id,
      },
    });
  };

  handleLoadSuccess = (payload) => {
    this.props.dispatch(loadingUpdateItems(payload));
  };

  render() {
    const {item, executing} = this.props;

    const icon = item.truck ? "loading" : "confirm";


    // Build Tags
    const tags = [];
    // tags.push(
    //   <Tag
    //     key={"confirm"}
    //     interactive
    //     icon={<Icon icon={icon} iconSize={11}/>}
    //
    //     className={item.truck ? "LoadingItemCard-Tag-Received" : "LoadingItemCard-Tag-Shipping"}
    //     onClick={this.handleItemSelection}
    //     loading={executing === item.id}
    //     disabled={!!executing}
    //   />
    // );

    tags.push(
      <Button
        small
        key={"confirm"}
        icon={"confirm"}
        className={item.truck ? "LoadingItemCard-Tag-Received" : "LoadingItemCard-Tag-Shipping"}
        onClick={this.handleItemSelection}
        loading={executing === item.id}
        disabled={!!executing}
      />
    );

    tags.push(<Tag key={"position"} className={"DeliveryItemCard-Tag-Position"}>#{item.position.toString().padStart(3, '0')}</Tag>);
    if ( item.type === 'product' ) {
      if ( item.product ) {
        tags.push(
          <Tag key={"product"} intent={"primary"}>
            {item.product.charAt(0).toUpperCase() + item.product.slice(1)}
          </Tag>
        );
      }
    }
    else {
      if (item.type) {
        tags.push(
          <Tag key={"type"} intent={"primary"}>
            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
          </Tag>
        );
      }
    }
    if ( item.service ) {
      tags.push(
        <Tag key={"service"} intent={"danger"}>Service</Tag>
      );
    }

    // Location Tag
    if ( item.truck ) {
      tags.push(
        <Tag key={"location"} className={"bp3-intent-data"}>
          Truck
        </Tag>
      );
    } else if ( item.location ) {
      tags.push(
        <Tag key={"location"} className={"bp3-intent-data"}>
          {item.location.charAt(0).toUpperCase() + item.location.slice(1)}
        </Tag>
      );
    } else {
      tags.push(
        <Tag key={"location"} className={"bp3-intent-bling"}>
          Shipping
        </Tag>
      );
    }

    // Storage Tag
    if ( item.truck ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-success"}>
          {item.truck}
        </Tag>
      );
    } else if ( item.storageBay && item.storageBay === 'INVALID' ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-danger"}>
          Not Delivered
        </Tag>
      );
    } else if ( item.storageBay ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-success"}>
          {item.storageBay}
        </Tag>
      );
    } else if ( item.location ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-warning"}>
          Unstaged
        </Tag>
      );
    }

    // Item Description
    let description;
    if ( this.state.expanded ) {
      description = (
        <Typography variant={"subtitle1"} className={"DeliveryItemCard-Description"}>
          {item.description}
        </Typography>
      );
    }


    return (
      <Card className={"DeliveryItemCard"}>
        <div className={"DeliveryItemCard-Flex"}>
          <div>
            {tags}
            <Typography className={"DeliveryItemCard-Title"}><b>{item.title}</b></Typography>
          </div>
          <div className={"DeliveryItemCard-Toggle"} style={{textAlign: 'right'}}>
            <Button
              small
              minimal
              icon={"caret-down"}
              onClick={this.handleToggle}
            />
          </div>
        </div>
        {description}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const selectedInstallation = state.LoadingReducer.selectedInstallation;
  return {
    installation: state.LoadingReducer.installations[selectedInstallation],
    executing: state.LoadingReducer.executing,
  };
}

export default connect(mapStateToProps)(LoadingItemCard);