import React from 'react';
import KeyButton from "./KeyButton";
import {Button, Callout, Icon} from "@blueprintjs/core";
import KeyEntry from "./KeyEntry";
import {applicationSubmitData} from "../../Actions/ApplicationActions";
import ReduxStore from "../../ReduxStore";
import {connect} from 'react-redux';

const keyPins = [
  {key: 1, value: " "},
  {key: 2, value: "ABC"},
  {key: 3, value: "DEF"},
  {key: 4, value: "GHI"},
  {key: 5, value: "JKL"},
  {key: 6, value: "MNO"},
  {key: 7, value: "PQRS"},
  {key: 8, value: "TUV"},
  {key: 9, value: "WXYZ"},
];

class Keypad extends React.PureComponent {
  state = {
    submitting: false,
    error: false,
    pin: '',
  };

  handleKeyPress = (key) => () => {
    if ( this.state.pin.length < 8 ) {
      const pin = this.state.pin + key;
       this.setState({
        pin: pin,
        error: false,
      });
    }
  };

  handleClearPress = () => {
    this.setState({pin: "", error: false});
  };

  handleEnterPress = () => {
    this.setState({submitting: true});
    ReduxStore.dispatch(applicationSubmitData('authenticate', {
      onError: this.handleSubmitError,
      data: {
        pin: this.state.pin
      },
    }));
  };

  handleSubmitError = (error) => {
    this.setState({
      submitting: false,
      pin: '',
      error: true,
    });
  };

  refreshApp = () =>  {
    window.location.reload();
  };

  render() {
    const {pin, error} = this.state;
    const {submitting} = this.props;

    const numberKeys = [];

    keyPins.forEach((button) => {
      numberKeys.push(
        <KeyButton
          key={button.key}
          primary={button.key}
          secondary={button.value}
          onClick={this.handleKeyPress(button.key)}
          disabled={submitting}
        />
      );
    });

    const errorMessage = error ? "Invalid Pin or Network Error. Please Try Again." : "Please enter your 8-digit pin to login.";

    return (
      <>
      <div className={"keypad-error"}>
        <Callout intent={error ? "danger" : "primary"} title={error ? "Login Error" : "Quo360 Mobile Login"}>
          {errorMessage}
        </Callout>

      </div>
      <div className="keypad">

        <div className="keypad-entry-buttons">
          <KeyEntry value={pin[0]}/>
          <KeyEntry value={pin[1]}/>
          <KeyEntry value={pin[2]}/>
          <KeyEntry value={pin[3]}/>
          <KeyEntry value={pin[4]}/>
          <KeyEntry value={pin[5]}/>
          <KeyEntry value={pin[6]}/>
          <KeyEntry value={pin[7]}/>
        </div>
        <div className="keypad-buttons">
          {numberKeys}
          <KeyButton
            clear={true}
            primary={<Icon icon="delete" color="#333333"/>}
            secondary="CLEAR"
            onClick={this.handleClearPress}
            disabled={!pin || submitting}
          />
          <KeyButton primary="0" secondary="" onClick={this.handleKeyPress(0)} disabled={submitting}/>
          <KeyButton
            enter={true}
            primary={<Icon icon="key-enter"  color="#333333" />}
            secondary="ENTER"
            disabled={pin.length !== 8 || submitting}
            onClick={this.handleEnterPress}
          />
        </div>
        <div>
          <Button
            icon={"refresh"}
            intent={"primary"}
            onClick={this.refreshApp}
          >
            Refresh App
          </Button>

          <div style={{float: 'right', display: 'inline', paddingRight: '2vh'}}><em>Version 1.2203.150</em></div>
        </div>
      </div>
      </>
    )
  }
}


export default Keypad;
