import React from 'react';
import {connect} from 'react-redux';
import {applicationChangeMode, applicationLoadData} from "../../Actions/ApplicationActions";
import StagingList from "./StagingList";
import StagingFilters from "./StagingFilters";
import ScannerPanel from "../Scanner/ScannerPanel";
import {Button} from "@blueprintjs/core";

class StagingPanel extends React.Component {
  componentDidMount() {
    if ( !this.props.initialized ) {
      this.props.dispatch(applicationLoadData('staging/load', {
        loading: 'Loading Jobs Requiring Staging...',
      }));
    }
  }

  startScanner = () => {
    this.props.dispatch(applicationChangeMode("scanner"));
    this.props.openPanel({
      component: ScannerPanel,
      title: "Delivery Scanner"
    });
  };


  render() {
    const {initialized, openPanel} = this.props;
    if ( !initialized ) return null;

    return (
      <div className={"StagingView"}>
        <StagingFilters/>
        <br/>
        <StagingList openPanel={openPanel}/>
        <div className={"ScannerOpenButton"}>
          <Button
            fill
            intent={"primary"}
            icon={"barcode"}
            text={"Start Scanner"}
            onClick={this.startScanner}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.StagingReducer.initialized,
    mode: state.StagingReducer.mode,
  };
}

export default connect(mapStateToProps)(StagingPanel);