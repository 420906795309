import React from 'react';
import {connect} from 'react-redux';
import StagingCard from "./StagingCard";
import StagingItemList from "./StagingItemList";
import StagingStorageSelection from "./StagingStorageSelection";
import {Button} from "@blueprintjs/core";
import ReduxStore from "../../ReduxStore";
import {applicationSubmitData} from "../../Actions/ApplicationActions";

class StagingSelectionPanel extends React.Component {
  handleCompletion = () => {
    this.props.closePanel();
    ReduxStore.dispatch(applicationSubmitData('staging/complete', {
      loading: 'Completing Staging...',
      data: {
        installation: this.props.installation.id
      },
    }));
  };

  render() {
    const {installation} = this.props;

    return (
      <div className={"StagingSelectionPanel"}>
        <StagingCard installation={installation}/>
        <StagingItemList items={installation.items}/>
        <StagingStorageSelection/>
        <Button
          fill={true}
          small={true}
          text={"Complete Staging"}
          intent={"success"}
          onClick={this.handleCompletion}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedInstallation = state.StagingReducer.selectedInstallation;
  return {
    installation: state.StagingReducer.installations[selectedInstallation],
  };
}

export default connect(mapStateToProps)(StagingSelectionPanel);