import React from 'react';
import {Button, Card, Icon} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";
import EmployeeName from "../Employee/EmployeeName";
import ScheduleDateGroup from "./ScheduleDateGroup";
import ReduxStore from "../../ReduxStore";
import {applicationSubmitData} from "../../Actions/ApplicationActions";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

class ScheduleCard extends React.PureComponent {

  handlePrintPacket = () => {
    ReduxStore.dispatch(applicationSubmitData('timelog/print', {
      'loading': 'Printing Appointment Packet',
      data: {
        inquiry: this.props.inquiry,
        workorder: this.props.id,
      },
    }));
  };

  handleDocumentDownload = () => {
    const {documentName, documentPath} = this.props;
    let link = window.document.createElement('a');
    link.href = documentPath;
    link.target = '_blank';
    link.download = documentName;
    link.click();
  };

  render() {
    const {id, inquiry, title, customer, address, type, employees, schedule, phones, documentPath, notes} = this.props;

    let icon;
    if ( type === 'install' ) {
      icon = (<Icon icon="build" />)
    } else {
      icon = (<Icon icon="search" />)
    }

    let employeeNames = [];
    employees.forEach((id) => {
      employeeNames.push(
        <EmployeeName id={id} key={id}/>
      );
    });

    let phoneNumbers = [];
    if ( phones && phones.length ) {

      phones.forEach((phone) => {
        phoneNumbers.push(

            <ListItem dense key={phoneNumbers.length} disableGutters>
              <ListItemText primary={phone.formatted} />
              <ListItemText secondary={phone.type_label} secondaryTypographyProps={{align: 'right'}} />
            </ListItem>

        );
      });

    }

    const phoneList = (
      <List
        dense
        disablePadding={true}
        subheader={
          <ListSubheader component={'div'} disableGutters disableSticky color={'primary'}>
            {customer}
          </ListSubheader>
        }
      >
        {phoneNumbers}
      </List>
    );


    let dates = [];
    Object.keys(schedule).forEach((dateKey) => {
      const dateGroup = schedule[dateKey];
      dates.push(
        <ScheduleDateGroup key={dateKey} group={dateGroup}/>
      )
    });

    let downloadButton = '';
    if ( documentPath ) {
      downloadButton = (
        <Button
          intent={"success"}
          icon={"download"}
          onClick={this.handleDocumentDownload}
        >Download</Button>
      );
    }
    
    
    let noteDisplay;
    if ( !!notes ) {
      noteDisplay = (
        <div>
          <br/>
          <div dangerouslySetInnerHTML={{__html: notes}}/>
        </div>
      );
    }


    return (
      <Card elevation={1} className="scheduleCard">
        <Typography component="h5">
          {icon} <b>#{inquiry}-{id} {title}</b>
        </Typography>
        <Typography variant="body2">
          {address}
        </Typography>
        {noteDisplay}
        <br/>
        {phoneList}
        <br/>
        {dates}

        <Button
          intent={"primary"}
          icon={"print"}
          onClick={this.handlePrintPacket}
        >Print Packet</Button>
        {" "}
        {downloadButton}
      </Card>
    )
  }
}

export default ScheduleCard;
