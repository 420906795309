import React from 'react';
import {connect} from 'react-redux';
import LoadingList from "./LoadingList";
import {applicationLoadData} from "../../Actions/ApplicationActions";
import "./Loading.css";
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";
import {loadingSetMode} from "../../Actions/LoadingActions";




class LoadingPanel extends React.Component {
  componentDidMount() {
    if ( !this.props.initialized ) {
      this.props.dispatch(applicationLoadData('loading/load', {
        loading: 'Loading Installations...',
      }));
    }
  }

  handleSetMode = (mode) => () => {
    this.props.dispatch(loadingSetMode(mode));
  };

  render() {
    const {initialized, openPanel, mode} = this.props;
    if ( !initialized ) return null;



    return (
      <div className={"LoadingView"}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fill
              text={"My Jobs"}
              icon={"timeline-events"}
              intent={mode === 'today' ? 'primary' : null}
              onClick={this.handleSetMode('today')}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              fill
              text={"Search"}
              icon={"search"}
              intent={mode === 'search' ? 'primary' : null}
              onClick={this.handleSetMode('search')}
              disabled={true}
            />
          </Grid>
        </Grid>
        <br />
        <LoadingList openPanel={openPanel}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.LoadingReducer.initialized,
    mode: state.LoadingReducer.mode,
  };
}

export default connect(mapStateToProps)(LoadingPanel);