import React from 'react';
import {Typography} from "@material-ui/core";
import {Button} from "@blueprintjs/core";
import {geolocated} from "react-geolocated";
import ReduxStore from "../../ReduxStore";
import {applicationLoadData, applicationSubmitData} from "../../Actions/ApplicationActions";

class ClockOutPanel extends React.Component {
  handleClockOut = () => {
    ReduxStore.dispatch(applicationLoadData('timelog/end', {
      onSuccess: this.handleSubmitSuccess,
      loading: 'Clocking Out...',
    }));
  };

  handleSubmitSuccess = (payload) => {
    if ( payload === 'Success' ) {
      this.props.closePanel();
    }
  };

  render() {

    return (
      <div className="ClockOutPanel">
        <Typography variant="h5">
          Clock Out
        </Typography>
        <br/>
        <Typography>
          Are you sure you wish to clock out?
        </Typography>
        <br/>
        <Button
          fill
          text={"Clock Out"}
          icon={"log-out"}
          intent={"danger"}
          onClick={this.handleClockOut}
        />
      </div>
    );
  }
}

export default ClockOutPanel;
