import React from 'react';
import {connect} from 'react-redux';
import StagingCard from "./StagingCard";


class StagingList extends React.Component {
  render() {
    const {installations, openPanel, locationFilter} = this.props;

    let cards = [];
    installations.forEach((installation, index) => {

      const isLocationValid = locationFilter.indexOf(installation.location) !== -1;

      if ( isLocationValid ) {
        cards.push(
          <StagingCard
            key={installation.id}
            index={index}
            installation={installation}
            openPanel={openPanel}
          />
        );
      }
    });

    return (
      <div className={"Installation-List"}>
        {cards}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    installations: state.StagingReducer.installations,
    locationFilter: state.StagingReducer.locationFilter,
  };
}

export default connect(mapStateToProps)(StagingList);