import React from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";
import {
  deliverySetLocationFilter,
  deliverySetManufacturerFilter,
  deliverySetTimeFilter
} from "../../Actions/DeliveryActions";

class DeliveryFilters extends React.PureComponent {
  handleTimeFilter = (timeFilter) => () => {
    this.props.dispatch(deliverySetTimeFilter(timeFilter));
  };
  handleLocationFilter = (location) => () => {
    this.props.dispatch(deliverySetLocationFilter(location));
  };
  handleManufacturerFilter = (manufacturer) => () => {
    this.props.dispatch(deliverySetManufacturerFilter(manufacturer));
  };

  render() {
    const {timeFilter, locationFilter, manufacturerFilter} = this.props;

    return (
      <div className={"DeliveryViewFilters"}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button
              fill={true}
              small={true}
              text={"Past"}
              onClick={this.handleTimeFilter('past')}
              className={timeFilter !== 'past' ? "bp3-intent-inactive" : null}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill={true}
              small={true}
              text={"Today"}
              onClick={this.handleTimeFilter('today')}
              className={timeFilter !== 'today' ? "bp3-intent-inactive" : null}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill={true}
              small={true}
              text={"Future"}
              onClick={this.handleTimeFilter('future')}
              className={timeFilter !== 'future' ? "bp3-intent-inactive" : null}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleLocationFilter('bothell')}
              className={locationFilter.indexOf('bothell') === -1 ? "bp3-intent-inactive" : null}
            >Bothell</Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleLocationFilter('tukwila')}
              className={locationFilter.indexOf('tukwila') === -1 ? "bp3-intent-inactive" : null}
            >Tukwila</Button>
          </Grid>
        </Grid>


        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Milgard')}
              className={manufacturerFilter.indexOf('Milgard') === -1 ? "bp3-intent-inactive" : null}
            >Milgard</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Simonton')}
              className={manufacturerFilter.indexOf('Simonton') === -1 ? "bp3-intent-inactive" : null}
            >Simonton</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Codel')}
              className={manufacturerFilter.indexOf('Codel') === -1 ? "bp3-intent-inactive" : null}
            >Codel</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Glass')}
              className={manufacturerFilter.indexOf('Glass') === -1 ? "bp3-intent-inactive" : null}
            >Glass</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Material')}
              className={manufacturerFilter.indexOf('Material') === -1 ? "bp3-intent-inactive" : null}
            >Material</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fill={true}
              small={true}
              onClick={this.handleManufacturerFilter('Other')}
              className={manufacturerFilter.indexOf('Other') === -1 ? "bp3-intent-inactive" : null}
            >Other</Button>
          </Grid>
        </Grid>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    timeFilter: state.DeliveryReducer.timeFilter,
    locationFilter: state.DeliveryReducer.locationFilter,
    manufacturerFilter: state.DeliveryReducer.manufacturerFilter,
  };
}

export default connect(mapStateToProps)(DeliveryFilters);