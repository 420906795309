import { combineReducers } from 'redux';
import ApplicationReducer from "./Application/ApplicationReducer";
import TimeClockReducer from "./Timeclock/TimeClockReducer";
import DeliveryReducer from "./DeliveryReducer";
import LoadingReducer from "./LoadingReducer";
import StagingReducer from "./StagingReducer";
import ScannerReducer from "./ScannerReducer";

const QuoAppReducers = combineReducers({
  ApplicationReducer,
  TimeClockReducer,
  DeliveryReducer,
  LoadingReducer,
  StagingReducer,
  ScannerReducer,
});

export default QuoAppReducers;