import React from 'react';
import Modal from "@material-ui/core/Modal";
import Loader from "./Loader";
import {Colors} from "@blueprintjs/core";
import { connect } from 'react-redux';

class LoadingOverlay extends React.Component {
  render() {
    const {loading} = this.props;

    let message;
    switch (loading) {
      case 'submit':
        message="Submitting...";
        break;

      case 'loading':
        message='Loading...';
        break;

      default:
        message=loading;
        break;
    }

    // Todo: Remove debug open line...
    return (
      <Modal
        //open={open === 'lock'}
        open={!!loading}
        className="loading-overlay"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          style: {backgroundColor: 'rgba(0, 0, 0, 0.8)'},
        }}
      >
        <div className="loading-modal">
          <Loader color={Colors.COBALT1} textColor="#FFF" suffix={false} message={message}/>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.ApplicationReducer.loading,
  }
}

export default connect(mapStateToProps, null)(LoadingOverlay);