import React from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";
import {stagingSetLocationFilter} from "../../Actions/StagingActions";

class StagingFilters extends React.Component {

  handleLocationFilter = (location) => () => {
    this.props.dispatch(stagingSetLocationFilter(location));
  };

  render() {
    const {locationFilter} = this.props;

    return (
      <div className={"StagingViewFilters"}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fill={true}
              small={true}
              text={"Bothell"}
              onClick={this.handleLocationFilter('bothell')}
              className={locationFilter.indexOf('bothell') === -1 ? "bp3-intent-inactive" : null}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              fill={true}
              small={true}
              text={"Tukwila"}
              onClick={this.handleLocationFilter('tukwila')}
              className={locationFilter.indexOf('tukwila') === -1 ? "bp3-intent-inactive" : null}
            />
          </Grid>
          {/*<Grid item xs={4}>*/}
          {/*  <Button*/}
          {/*    fill={true}*/}
          {/*    small={true}*/}
          {/*    text={"Search"}*/}
          {/*    icon={"search"}*/}
          {/*    disabled={true}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationFilter: state.StagingReducer.locationFilter,
  };
}

export default connect(mapStateToProps)(StagingFilters);