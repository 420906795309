import React from 'react';
import UnixDate from "../UnixDate/UnixDate";
import UnixTime from "../../Lib/UnixTime/UnixTime";
import EmployeeName from "../Employee/EmployeeName";

class ScheduleDateGroup extends React.Component {

  static intersperce(arr, sep) {
    if (arr.length === 0) {
      return [];
    }

    return arr.slice(1).reduce(function(xs, x, i) {
      return xs.concat([sep, x]);
    }, [arr[0]]);
  };

  render() {
    const {group} = this.props;
    console.log(group);

    let dateText;
    const firstDay = Math.min(...group.dates);
    const lastDay = Math.max(...group.dates);
    if ( firstDay === lastDay ) {
      dateText = (
        <UnixDate timestamp={firstDay} format="MMMM Do"/>
      );
    } else if (UnixTime.areTimestampsInSameMonth(firstDay, lastDay) ) {
      dateText = (<><UnixDate timestamp={firstDay} format="MMMM Do"/> - <UnixDate timestamp={lastDay} format="Do"/></>);
    } else {
      dateText = (<><UnixDate timestamp={firstDay} format="MMMM Do"/> - <UnixDate timestamp={lastDay} format="MMMM Do"/></>);
    }


    let timeGroups = [];
    group.times.forEach((timeGroup, index) => {
      let employeeNames = [];
      timeGroup.employees.forEach((employeeId) => {
        employeeNames.push(<EmployeeName key={employeeId} id={employeeId} short/>);
      });

      console.log(timeGroup.employees);
      timeGroups.push(
        <div className="schedule-time-group" key={index}>
          <div className="schedule-time-group-employees">
            {ScheduleDateGroup.intersperce(employeeNames, ', ')}
          </div>
          <div className="schedule-time-group-time">
            <UnixDate timestamp={timeGroup.start} format="h:mm A"/> - <UnixDate timestamp={timeGroup.end} format="h:mm A"/>
          </div>
        </div>
      );
    });



    return (
      <div className="scheduleGroup">
        <b>{dateText}</b>
        <div className="schedule-time-groups">
          {timeGroups}
        </div>
      </div>
    )
  }
}

export default ScheduleDateGroup;
