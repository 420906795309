/**
 * Primary Configuration Object
 * Used to avoid having to type process.env for every variable name.
 * @type {{APP_NAME: string}}
 */

const appSource = process.env.NODE_ENV !== 'production' ?
  'https://development.quo360.com/react/timeclock/api' : 'https://www.quo360.com/react/timeclock/api';


const Config = {
  'APP_VERSION': 1,
  'APP_VERSION_DISPLAY': '0.0.1-Dev',
  'APP_NAME': process.env.REACT_APP_APP_NAME || 'Quo360 Mobile Timeclock',
  'APP_SOURCE': appSource,
  'COLOR': {
    'PRIMARY': '#005295',
  },
  'STATE_LABEL': 'State',
  'DEFAULT_STATE': 'WA',
  //'LOGO': require('images/logo/MHS.jpg'),
  'STATE_LIST': {
    'WA': 'Washington',
    'OR': 'Oregon',
    //'ND': 'North Dakota',
    //'SD': 'South Dakota',
  },
};

export default Config;
