import React from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {Button} from "@blueprintjs/core";

class DeliveryStorageFiltersSouth extends React.Component {
  render() {
    const {onClick, storageFilter, executingStorage} = this.props;

    return (
      <div className={"DeliveryStorageFilters"}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button
              fill
              text={"Windows"}
              intent={storageFilter === 'Windows' ? 'primary' : null}
              onClick={onClick('Windows')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill
              text={"Doors"}
              intent={storageFilter === 'Doors' ? 'primary' : null}
              onClick={onClick('Doors')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fill
              text={"Glass"}
              onClick={onClick('Glass')}
              intent={storageFilter === 'Glass' ? 'primary' : null}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fill
              text={"Parts"}
              onClick={onClick('Parts')}
              intent={storageFilter === 'Parts' ? 'primary' : null}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill
              text={"Service"}
              intent={storageFilter === 'Service' ? 'primary' : null}
              onClick={onClick('Service')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill
              text={"Returns"}
              intent={storageFilter === 'Returns' ? 'primary' : null}
              onClick={onClick('Returns')}
              disabled={!!executingStorage}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fill
              text={"Material"}
              intent={storageFilter === 'Material' ? 'primary' : null}
              onClick={onClick('Material')}
              disabled={!!executingStorage}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.counterReducer,
  };
}

export default connect(mapStateToProps)(DeliveryStorageFiltersSouth);