class UnixTime {
  static getDateToday() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static getTimestampDate(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);
    return date;
  }

  static getNow() {
    const date = new Date();
    return Math.floor(date.getTime() / 1000);
  }

  static getTodayStart() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return Math.floor(date.getTime() / 1000);
  }

  static getTimestampStart(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);
    date.setHours(0, 0, 0, 0);
    return Math.floor(date.getTime() / 1000);
  }

  static getDateTimestamp(date) {
    return Math.floor(date.getTime() / 1000);
  }

  static areTimestampsInSameMonth(first, last) {
    let firstDate = UnixTime.getTimestampDate(first);
    let lastDate = UnixTime.getTimestampDate(last);
    return firstDate.getMonth() === lastDate.getMonth();
  }

  static getWeekStart() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const timestamp = Math.floor(date.getTime() / 1000);
    return timestamp - 86400 * date.getDay();
  }
}

export default UnixTime;