const ScannerReducer = (state, action) => {
  switch ( action.type ) {
    case 'SCANNER_SET_STORAGE_FILTER':
      return Object.assign({}, state, {
        storageFilter: action.storageFilter,
      });


    case 'APPLICATION_UPDATE_STATE':
      const data = action.data;
      if ( data['@scanner'] ) {
        return Object.assign({}, state, data['@scanner']);
      } else return state;

    default:
      if ( state === undefined ) {
        return {
          initialized: false,
          storageBays: {},
          location: 'tukwila',
          storageFilter: 'Exterior',
        };
      } else {
        return state;
      }
  }
};

export default ScannerReducer;