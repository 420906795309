import React from 'react';
import {connect} from 'react-redux';
import {Typography} from "@material-ui/core";
import TimeClockLogItem from "./TimeClockLogItem";

class TimeClockLog extends React.Component {
  render() {
    const {log} = this.props;
    if ( !log ) return null;

    let logRows = [];
    for ( let i = 0; i < Math.min(6, log.length); i++ ) {
      const item = log[i];
      if ( item.type !== 'day' ) {
        logRows.push(
          <TimeClockLogItem
            key={item.id}
            {...item}
          />
        );
      }
    }


    return (
      <div className="TimeClockLog">
        <Typography variant="caption">
          <b>Recent Time Clock Events:</b>
        </Typography>
        {logRows}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    active: state.TimeClockReducer.active,
    log: state.TimeClockReducer.log,
  };
}

export default connect(mapStateToProps)(TimeClockLog);