import React from 'react';
import {Button, FormGroup, TextArea} from "@blueprintjs/core";
import ClockJobSelection from "./ClockJobSelection";
import { geolocated } from "react-geolocated";
import ReduxStore from "../../ReduxStore";
import {applicationSubmitData} from "../../Actions/ApplicationActions";
import GPSStatus from "../GPS/GPSStatus";

class ClockInPanel extends React.Component {
  state = {
    mode: null,
    action: null,
    job: null,
    notes: "",
  };

  switchAction = (action) => () => {
    this.setState({
      action: this.state.action === action ? null : action,
    })
  };

  switchMode = (mode) => {
    this.setState({
      mode: mode,
    })
  };

  confirmClock = () => {
    // Base Data
    const data = {
      type: this.state.action,
      notes: this.state.notes,
      workorder: this.state.workorder,
      action: this.state.mode,
    };

    // GPS Coordinates
    if ( this.props.isGeolocationAvailable && this.props.coords ) {
      data.lat = this.props.coords.latitude;
      data.lng = this.props.coords.longitude;
    }

    ReduxStore.dispatch(applicationSubmitData('timelog/start', {
      onSuccess: this.handleSubmitSuccess,
      loading: 'Clocking In...',
      data: data,
    }));
  };

  confirmJobClock = (info) => {
    // Base Data
    const data = {
      type: 'job',
      workorder: info.workorder,
      action: info.mode,
    };

    // GPS Coordinates
    if ( this.props.isGeolocationAvailable && this.props.coords ) {
      data.lat = this.props.coords.latitude;
      data.lng = this.props.coords.longitude;
    }

    ReduxStore.dispatch(applicationSubmitData('timelog/start', {
      onSuccess: this.handleSubmitSuccess,
      loading: 'Clocking In...',
      data: data,
    }));
  };

  handleSubmitSuccess = (payload) => {
    if ( payload === 'Success' ) {
      this.props.closePanel();
    }
  };

  handleNotes = (event) => {
    this.setState({notes: event.target.value});
  };

  render() {
    const {} = this.props;
    const {action, notes} = this.state;



    // Shop Time Description
    let shopNotes;
    if ( action === 'shop' ) {
      shopNotes = (
        <FormGroup
          label={<b>Shop Time Notes</b>}
          helperText="Enter a short description of the work being performed."
        >
          <TextArea
            style={{marginBottom: '2vw'}}
            growVertically={true}
            large={true}
            intent={"primary"}
            onChange={this.handleNotes}
            value={notes}
            fill={true}
          />
        </FormGroup>
      );
    }

    // Confirm Button
    let confirmButton;
    if ( !action || action !== 'job' ) {
      confirmButton = (
        <Button
          icon="confirm"
          text="Confirm Clock-In"
          intent="success"
          fill={true}
          disabled={!action || (action === 'shop' && !notes) }
          onClick={this.confirmClock}
        />
      );
    }

    // Geo Coordinates
    // let geoStatus;
    // if ( !this.props.isGeolocationAvailable ) {
    //   return (<div>GPS Unavailable. You must enable GPS on your device to use the time clock.</div>);
    // } else if ( !this.props.isGeolocationEnabled ) {
    //   return (<div>GPS Disabled. You must enable GPS on your device to use the time clock.</div>);
    // } else if ( this.props.coords ) {
    //   geoStatus = (<div>GPS Coordinates: {this.props.coords.latitude.toFixed(6)}, {this.props.coords.longitude.toFixed(6)}</div>);
    // } else {
    //   return (<><br/><div>Requesting GPS Coordinates</div></>);
    // }

    const geoStatus = (
      <GPSStatus
        coords={this.props.coords}
        enabled={this.props.isGeolocationEnabled}
        available={this.props.isGeolocationAvailable}
      />
    );
    if ( !this.props.isGeolocationAvailable || !this.props.isGeolocationEnabled || !this.props.coords ) return geoStatus;

    // Job Selection
    let jobList;
    if ( action === 'job' ) {
      jobList = (
        <ClockJobSelection
          closePanel={this.props.closePanel}
          coords={this.props.coords}
          confirmJobClock={this.confirmJobClock}
        />
      );
    }




    return (
      <div className="ClockInPanel">



        <div className="clock-in-list">
          <div className="clock-in-selection">
            <Button
              fill={true}
              icon="build"
              text="Start Job"
              large={true}
              intent={action === 'job' ? 'primary' : null}
              onClick={this.switchAction('job')}
            />
          </div>
          <div className="clock-in-selection">
            <Button
              fill={true}
              icon="wrench"
              text="Shop Time"
              large={true}
              intent={action === 'shop' ? 'primary' : null}
              onClick={this.switchAction('shop')}
            />
          </div>
          <div className="clock-in-selection">
            <Button
              fill={true}
              icon="office"
              text="Office"
              large={true}
              intent={action === 'meeting' ? 'primary' : null}
              onClick={this.switchAction('meeting')}
            />
          </div>

        </div>
        {jobList}
        {shopNotes}
        {confirmButton}
        {geoStatus}
      </div>
    );
  }
}

//export default ClockInPanel;
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  watchPosition: true,
  userDecisionTimeout: 5000,
})(ClockInPanel);