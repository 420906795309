import React from 'react';
import {connect} from 'react-redux';
import {Typography} from "@material-ui/core";
import EmployeeName from "../Employee/EmployeeName";
import UnixDate from "../UnixDate/UnixDate";
import {Button} from "@blueprintjs/core";
import TimeClockTicker from "./TimeClockTicker";
import UnixTime from "../../Lib/UnixTime/UnixTime";

class TimeClockStatus extends React.Component {
  interval = null;
  state = { time: 0 };

  componentDidMount() {
    if ( !this.interval && this.props.clockedIn ) {
      this.interval = setInterval(this.calculateTime, 1000);
    }
    // Calculate Time Once
    this.calculateTime();
  }

  componentWillUnmount() {
    if ( this.interval ) {
      clearInterval(this.interval);
    }
  }

  calculateTime = () => {
    //console.log("CALCULATING TIME");
    //if ( this.props.clockedIn ) {
      let time = this.props.weekHours;
      if (this.props.clockedIn) time = UnixTime.getNow() - this.props.sessionStart;
      this.setState({time: time});

    // } else if ( this.interval ) {
    //   clearInterval(this.interval);
    // }
  };

  render() {
    const {clockedIn, employeeId, activeTitle, lunch} = this.props;
    const {time} = this.state;

    // Calculate the Time to display in Week Hours
    //let time = weekHours;
    //if ( clockedIn ) time += UnixTime.getNow() - sessionStart;

    const clockedInText = lunch ? "On Lunch/Break" : "Clocked In";
    const title = clockedIn ? activeTitle : 'This Week';


    return (
      <>
        <Typography variant="h3" align="center">
          <EmployeeName id={employeeId}/>
        </Typography>
        <Button
          fill
          minimal
          text={clockedIn ? clockedInText : "Clocked Out"}
          intent={clockedIn ? "success" : "danger"}
        />
        <br/>
        <Typography variant="h6"  align="center">
          {title ? title.substring(0, 30) : ""}
        </Typography>
        <TimeClockTicker time={time}/>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeId: state.ApplicationReducer.system.activeEmployee,
    sessionStart: state.TimeClockReducer.sessionStart,
    weekHours: state.TimeClockReducer.weekHours,
    clockedIn: state.TimeClockReducer.clockedIn,
    lunch: state.TimeClockReducer.lunch,
    activeTitle: state.TimeClockReducer.active.title,
  };
}

export default connect(mapStateToProps)(TimeClockStatus);