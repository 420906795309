import React from 'react';
import ReduxStore from "../../ReduxStore";
import {Typography} from "@material-ui/core";
import {Card} from "@blueprintjs/core";
import StagingSelectionPanel from "./StagingSelectionPanel";
import {stagingSetSelection} from "../../Actions/StagingActions";
import StagingCardTags from "./StagingCardTags";

class StagingCard extends React.Component {

  handleSelection = () => {
    const installation = this.props.installation;
    ReduxStore.dispatch(stagingSetSelection(this.props.index));
    this.props.openPanel({
      component: StagingSelectionPanel,
      title: '#' + installation.inquiry + '-' + installation.id,
    });
  };

  render() {
    const {installation} = this.props;

    let notes;
    if ( this.props.openPanel && installation.notes ) {
      notes = (
        <Typography variant={"subtitle1"} className={"DeliveryCard-Notes"}>
          {installation.notes}
        </Typography>
      );
    }

    return (
      <Card
        interactive
        className={"Delivery-Card"}
        onClick={this.props.openPanel ? this.handleSelection : null}
      >
        <Typography>
          <b>#{installation.inquiry}-{installation.id}: {installation.customer}</b>
        </Typography>
        <StagingCardTags installation={installation}/>
        {notes}
      </Card>
    );
  }
}

export default StagingCard;