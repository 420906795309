export const loadingSetSelection = (index) => ({
  type: 'LOADING_SET_SELECTION',
  index: index,
});

export const loadingToggleItem = (item) => ({
  type: 'LOADING_TOGGLE_ITEM',
  item: item,
});

export const loadingUpdateItems = (items) => ({
  type: 'LOADING_UPDATE_ITEMS',
  items: items,
});

export const loadingSetMode = (mode) => ({
  type: 'LOADING_SET_MODE',
  mode: mode,
});