import UnixTime from "../../Lib/UnixTime/UnixTime";

const TimeClockReducer = (state, action) => {
  switch ( action.type ) {
    // When the Timeclock is updated.
    case 'TIME_CLOCK_UPDATE':
      return Object.assign({}, state, action.data);

    case 'APPLICATION_UPDATE_STATE':
      const data = action.data;
      if ( data['@timeclock'] ) {
        return Object.assign({}, state, data['@timeclock']);
      } else return state;

    default:
      if ( state === undefined ) {
        return {
          initialized: false,
          clockedIn: false,
          sessionStart: 0,
          weekHours: 0,
          lunch: false,
          log: [],
          active: {title: ''},
        }
      } else {
        return state;
      }
  }
};

export default TimeClockReducer;
