import React from 'react';
import {connect} from 'react-redux';
import {Button, Callout, Divider} from "@blueprintjs/core";
import "./TimeClock.css";
import TimeClockStatus from "./TimeClockStatus";
import ClockInForm from "./ClockInForm";
import TimeClockLog from "./TimeClockLog";
import ClockOutForm from "./ClockOutForm";
import {Typography} from "@material-ui/core";

class TimeClockPanel extends React.Component {
  render() {
    //const {clockedIn} = this.props;

    // Start Day Button
    //let clockInForm;


    return (
      <div className="TimeClockView">
        <TimeClockStatus/>
        <br/>
        <TimeClockLog/>
        <br/>
        <Divider/>
        <br/>
        <ClockInForm
          openPanel={this.props.openPanel}
        />
        <ClockOutForm
          openPanel={this.props.openPanel}
        />
        <br/>
        <Callout intent={"warning"} icon={null}>
          <Typography variant={"caption"}>
            Clocking out for lunch breaks is mandatory. 30 minutes will be automatically deducted when you fail to do so.
          </Typography>
        </Callout>
      </div>
    );
  }
}

export default TimeClockPanel;