import React from 'react';
import {connect} from 'react-redux';
import LoadingCard from "./LoadingCard";

class LoadingList extends React.Component {
  render() {
    const {installations, openPanel} = this.props;

    let cards = [];
    installations.forEach((installation, index) => {
      cards.push(
        <LoadingCard
          key={installation.id}
          index={index}
          installation={installation}
          openPanel={openPanel}
        />
      );
    });

    return (
      <div className={"Loading-List"}>
        {cards}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    installations: state.LoadingReducer.installations,
  };
}

export default connect(mapStateToProps)(LoadingList);