import React from 'react';
import './App.css';
import { connect } from 'react-redux';
import Keypad from "./Components/Keypad/Keypad";
import LoadingOverlay from "./Components/Loader/LoadingOverlay";
import {applicationInitialize} from "./Actions/ApplicationActions";
import Loader from "./Components/Loader/Loader";
import CssBaseline from "@material-ui/core/CssBaseline";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import ScheduleView from "./Components/Schedule/ScheduleView";
import QuickView from "./Components/QuickView/QuickView";
import {PanelStack} from "@blueprintjs/core";
import TimeClockPanel from "./Components/TimeClock/TimeClockPanel";
import Quo360Panel from "./Components/Quo360/Quo360Panel";

const theme = createMuiTheme({
  // spacing: { unit: 4 },
  overrides: {
    // Typography Overrides
    MuiTypography: {
      //body2: { fontWeight: 400 },
      h1: { fontSize: '2.25rem' },
      h2: { fontSize: '2rem' },
      h3: { fontSize: '1.75rem' },
      h4: { fontSize: '1.50rem' },
      h5: { fontSize: '1.25rem' },
      h6: { fontSize: '1rem' },
    },
  }
});

class App extends React.Component {

  componentDidMount() {
    // Initialize
    if ( !this.props.initialized ) {
      this.props.dispatch(applicationInitialize());
    }
  }

  // TODO: ShouldUpdate
  render() {
    const { authentication, initialized } = this.props;

    // Application View
    let applicationView;
    // TODO: If ( newVersion.... )
    if ( !initialized && !authentication.access ) applicationView = (<Loader message="Initializing Application..."/>);
    else if ( !authentication.access ) applicationView = (<Keypad/>);
    //else applicationView = (<ScheduleView/>);
    //else applicationView = (<QuickView/>);
    //else applicationView = (<TimeClockView/>);
    else applicationView = (
      <PanelStack
        className="App-PanelStack"
        initialPanel={{
          component: Quo360Panel,
          title: 'Dashboard'
          //component: TimeClockPanel,
          //title: "Time Clock"
        }}
      />
    );


    //let initialComponent;





    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline/>
          {applicationView}
          <LoadingOverlay/>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.ApplicationReducer.initialized,
    loading: state.ApplicationReducer.loading,
    authentication: state.ApplicationReducer.authentication,
  };
}

export default connect(mapStateToProps)(App);

