import React from 'react';
import {Icon} from "@blueprintjs/core";

class KeyEntry extends React.PureComponent {
  render() {
    const { value } = this.props;
    return (
      <div className="keypad-entry">
        { value && (<Icon icon="asterisk" color="#fff"/>)}
      </div>
    )
  }
}

export default KeyEntry;