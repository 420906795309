import CloneDeep from "lodash/cloneDeep";

const StagingReducer = (state, action) => {
  switch ( action.type ) {

    case 'STAGING_SET_SELECTION':
      return Object.assign({}, state, {
        selectedInstallation: action.index,
      });

    case 'STAGING_SET_ITEM_SELECTION':
      return Object.assign({}, state, {
        selectedItem: action.item,
        storageFilter: action.item && action.item.storage ? action.item.storage : state.storageFilter,
      });

    case 'STAGING_SET_STORAGE_FILTER':
      if ( state.storageFilter !== action.storageFilter ) {
        return Object.assign({}, state, {
          storageFilter: action.storageFilter,
        });
      } else return state;

    case 'STAGING_SET_LOCATION_FILTER': {
      //let filter = state.locationFilter.slice(0);
      //const index = filter.indexOf(action.location);
      // if (index === -1) filter.push(action.location);
      // else if ( filter.length === 2 ) filter = ['OTHER', 'bothell', 'tukwila'];
      // else
      const filter = ['OTHER', action.location];
      return Object.assign({}, state, {
        locationFilter: filter,
      });
    }

    case 'STAGING_SET_ITEM_STORAGE': {
      const item = Object.assign({}, state.selectedItem, {
        storage: action.bay,
      });
      return Object.assign({}, state, {
        executingStorage: action.bay,
        selectedItem: item,
      });
    }

    case 'STAGING_UPDATE_ITEMS': {
      const installations = CloneDeep(state.installations);
      const installation = installations[state.selectedInstallation];
      installation.items = action.items;
      installation.itemCount = action.items.length;
      return Object.assign({}, state, {
        installations: installations,
        executingStorage: false,
        selectedItem: null,
      });
    }

    case 'APPLICATION_CHANGE_MODE': {
      return Object.assign({}, state, {
        initialized: false,
      });
    }

    case 'APPLICATION_UPDATE_STATE':
      const data = action.data;
      if ( data['@staging'] ) {
        return Object.assign({}, state, data['@staging']);
      } else return state;

    default:
      if ( state === undefined ) {
        return {
          initialized: false,
          loading: false,
          installations: [],
          executing: false,
          selectedInstallation: null,
          locationFilter: ['tukwila', 'bothell'],
          selectedItem: null,
          mode: 'today',
          storageBays: {},
          storageFilter: 'Exterior',
          executingStorage: false,
        }
      } else {
        return state;
      }
  }
};

export default StagingReducer;
