import React from 'react';
import {Button} from "@blueprintjs/core";
import ClockInPanel from "./ClockInPanel";
import {connect} from "react-redux";

class ClockInForm extends React.PureComponent {
  // Handles The Primary Clock-In Button
  toggleClockIn = () => {
    this.props.openPanel({
      component: ClockInPanel,
      props: { enabled: true },
      title: 'Clock-In',
    });
  };

  render() {
    const {clockedIn} = this.props;
    if ( clockedIn ) return null;

    return (
      <div>
        <div className="app-list">
          <div className="app-list-selection"/>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="log-in"
              text="Clock In"
              large={true}
              intent="success"
              onClick={this.toggleClockIn}
            />
          </div>
          <div className="app-list-selection"/>
        </div>
        {/*<Button*/}
        {/*  fill={true}*/}
        {/*  text={clockedIn ? "Clock-Out" : "Clock-In"}*/}
        {/*  icon={clockedIn ? "log-out" : "log-in"}*/}
        {/*  onClick={this.toggleClockIn}*/}
        {/*  intent={clockedIn ? "danger" : "success"}*/}
        {/*/>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clockedIn: state.TimeClockReducer.clockedIn,
  };
}

export default connect(mapStateToProps)(ClockInForm);
//export default ClockInForm;