import React from 'react';
import {connect} from 'react-redux';
import {Button} from "@blueprintjs/core";
import Grid from "@material-ui/core/Grid";

class ScannerStorageBays extends React.Component {
  handleStorageSelection = (bay) => () => {
    const locationCode = this.props.location === 'bothell' ? 'N' : 'S';
    const fullBay = "SB-" + locationCode + "-" + bay;
    this.props.onSelection(fullBay);
  };

  render() {
    const {currentBay, location, storageBays, storageFilter} = this.props;

    //const defaultStorage = location === "bothell" ? 'Exterior' : 'Windows';
    const filteredBays = storageBays[location][storageFilter];

    let bayButtons = [];
    filteredBays.forEach((bay) => {
      let className = "DeliveryStorageBay";
      if ( currentBay === bay ) className += " active";
      if ( filteredBays.length === 1 ) className += " single";
      bayButtons.push(
        <div key={bay} className={className}>
          <Button
            text={bay}
            className={"DeliveryStorageBay-Content"}
            intent={currentBay === bay ? 'success' : null}
            onClick={this.handleStorageSelection(bay)}
          />
        </div>
      );
    });

    return (
      <>
        <div className={"DeliveryStorageBays"}>
          {bayButtons}
          {location}
        </div>
        <div className={"DeliveryStorageSelection-Actions"}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fill
                text={"Cancel"}
                intent={"danger"}
                onClick={this.props.onCancel}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.ScannerReducer.location,
    storageBays: state.ScannerReducer.storageBays,
    storageFilter: state.ScannerReducer.storageFilter,
  };
}

export default connect(mapStateToProps)(ScannerStorageBays);