import React from 'react';
import {connect} from 'react-redux';
import EmployeeName from "../Employee/EmployeeName";
import Schedule from "../../Lib/Schedule/Schedule";
import QuickAppt from "./QuickAppt";

class QuickViewRow extends React.Component {
  render() {
    const { employee, periodStart, periodEnd, schedule, type } = this.props;

    const quickSchedule = Schedule.getEmployeeQuickSchedule(schedule, employee, periodStart, periodEnd, type);
    if ( !quickSchedule || !quickSchedule.length ) return null;

    let appointments = [];
    quickSchedule.forEach((appt, index) => {
      appointments.push(
        <QuickAppt
          key={index}
          periodStart={periodStart}
          periodEnd={periodEnd}
          {...appt}
        />
      )
    });


    return (
      <div className="CQV-row">
        <div className="CQV-row-employee"><EmployeeName id={employee} /></div>
        <div className="CQV-grid">
          <div className="CQV-grid-week week-1">
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
          </div>
          <div className="CQV-grid-week week-2">
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
            <div className="CQV-grid-day"/>
          </div>
        </div>
        <div className="CQV-appts">
          {appointments}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    schedule: state.ApplicationReducer.system.schedule,
  };
}

export default connect(mapStateToProps)(QuickViewRow);