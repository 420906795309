import React from 'react';
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";

class ScheduleMap extends React.Component {
  render() {
    const {google, schedule} = this.props;
    if ( !schedule ) return null;


    let markers = [];
    let latAvg = 0;
    let lngAvg = 0;
    schedule.forEach((item) => {
      latAvg += item.lat;
      lngAvg += item.lng;
      markers.push(
        <Marker
          key={item.id}
          title={item.title}
          position={{lat: item.lat, lng: item.lng}}
          // icon={{
          //   url: "/icons/mclendon.png",
          //   anchor: anchor,
          //   scaledSize: scaledSize,
          // }}
        />
      );
    });
    latAvg /= schedule.length;
    lngAvg /= schedule.length;



    return (
      <Map
        google={google}
        zoom={9}
        style={{width: '100%', height: '100%'}}
        initialCenter={{ lat: latAvg, lng: lngAvg}}
        center={{ lat: latAvg, lng: lngAvg}}
        containerStyle={{
          width: '96vw',
          height: '96vw',
          position: 'relative',
        }}
      >
        {markers}
      </Map>
    )
  }
}

//export default ScheduleMap;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAXJxpaCWIR0W8PvAVkW3hsQ2-OoASJyoY'
})(ScheduleMap);