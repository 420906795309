import React from 'react';
 import isEqual from "react-fast-compare";
import ScheduleCard from "./ScheduleCard";

class ScheduleList extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.props.schedule, nextProps.schedule);
  }

  render() {
    const { schedule } = this.props;
    if ( !schedule ) return "There are no appointments.";



    // COmpile Cards
    let cards = [];
    schedule.forEach((job) => {
      cards.push(
        <ScheduleCard
          key={job.inquiry}
          {...job}
        />
      );
    });

    return (
      <div className="scheduleList">
        {cards}
      </div>
    )
  }
}

export default ScheduleList;