import React from 'react';
import {connect} from 'react-redux';
import {deliverySetItemSelection, deliverySetItemStorageBay, deliveryUpdateItems} from "../../Actions/DeliveryActions";
import {Button} from "@blueprintjs/core";
import DataLoader from "../../Lib/Api/DataLoader";
import Grid from "@material-ui/core/Grid";

class DeliveryStorageBays extends React.Component {

  handleStorageSelection = (bay) => () => {
    const selectedItem = this.props.selectedItem;
    this.props.dispatch(deliverySetItemStorageBay(bay));
    DataLoader.submitData('delivery/store', {
      onSuccess: this.handleStorageSuccess,
      data: {
        item: selectedItem.id,
        storage: this.props.storageFilter,
        storageBay: bay,
        storageNotes: '',
        location: this.props.location,
        delivery: this.props.deliveryId,
      },
    });
  };

  handleShippingSelection = () => {
    const selectedItem = this.props.selectedItem;
    this.props.dispatch(deliverySetItemStorageBay("shipping"));
    DataLoader.submitData('delivery/store', {
      onSuccess: this.handleStorageSuccess,
      data: {
        item: selectedItem.id,
        storage: null,
        storageBay: null,
        storageNotes: '',
        location: null,
        delivery: this.props.deliveryId,
      },
    });
  };

  handleDeliveredSelection = () => {
    const selectedItem = this.props.selectedItem;
    this.props.dispatch(deliverySetItemStorageBay("delivered"));
    DataLoader.submitData('delivery/store', {
      onSuccess: this.handleStorageSuccess,
      data: {
        item: selectedItem.id,
        storage: null,
        storageBay: null,
        storageNotes: '',
        location: this.props.location,
        delivery: this.props.deliveryId,
      },
    });
  };

  handleMissingSelection = () => {
    const selectedItem = this.props.selectedItem;
    this.props.dispatch(deliverySetItemStorageBay("missing"));
    DataLoader.submitData('delivery/store', {
      onSuccess: this.handleStorageSuccess,
      data: {
        item: selectedItem.id,
        storage: null,
        storageBay: 'INVALID',
        storageNotes: '',
        location: null,
        delivery: this.props.deliveryId,
      },
    });
  };

  handleStorageSuccess = (payload) => {
    this.props.dispatch(deliveryUpdateItems(payload));
  };

  cancelSelection = () => {
    this.props.dispatch(deliverySetItemSelection(null));
  };

  render() {
    const {selectedItem, storageFilter, storageBays, location, executingStorage} = this.props;
    if ( !selectedItem || !location || !storageBays[location] || !storageBays[location][storageFilter] ) return null;

    const filteredBays = storageBays[location][storageFilter];

    let bayButtons = [];
    filteredBays.forEach((bay) => {
      let className = "DeliveryStorageBay";
      if ( selectedItem.storage === bay ) className += " active";
      if ( filteredBays.length === 1 ) className += " single";
      bayButtons.push(
        <div key={bay} className={className}>
          <Button
            text={bay}
            className={"DeliveryStorageBay-Content"}
            intent={bay === executingStorage || selectedItem.storageBay === bay ? 'success' : null}
            disabled={!!executingStorage}
            onClick={this.handleStorageSelection(bay)}
            loading={bay === executingStorage}
          />
        </div>
      );
    });

    return (
      <>
        <div className={"DeliveryStorageBays"}>
          {bayButtons}
        </div>
        <div className={"DeliveryStorageSelection-Actions"}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button
                fill
                icon={"geolocation"}
                text={"Shipping"}
                disabled={!!executingStorage}
                onClick={this.handleShippingSelection}
                loading={executingStorage === 'shipping'}
                intent={'shipping' === executingStorage || (!executingStorage && !selectedItem.location ) ? 'success' : null}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                fill
                icon={"box"}
                text={"Delivered"}
                disabled={!!executingStorage}
                onClick={this.handleDeliveredSelection}
                loading={executingStorage === 'delivered'}
                intent={'delivered' === executingStorage || (!executingStorage && selectedItem.location && !selectedItem.storageBay ) ? 'success' : null}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                fill
                intent={"danger"}
                icon={"disable"}
                text={"Not Received"}
                disabled={!!executingStorage}
                onClick={this.handleMissingSelection}
                loading={executingStorage === 'missing'}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fill
                text={"Cancel"}
                disabled={!!executingStorage}
                onClick={this.cancelSelection}
                loading={executingStorage === 'missing'}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const selectedDelivery = state.DeliveryReducer.selectedDelivery;
  return {
    storageFilter: state.DeliveryReducer.storageFilter,
    storageBays: state.DeliveryReducer.storageBays,
    selectedItem: state.DeliveryReducer.selectedItem,
    location: state.DeliveryReducer.deliveries[selectedDelivery].location,
    executingStorage: state.DeliveryReducer.executingStorage,
    deliveryId: state.DeliveryReducer.deliveries[selectedDelivery].id,
  };
}

export default connect(mapStateToProps)(DeliveryStorageBays);