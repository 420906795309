import React from 'react';
import {connect} from 'react-redux';
import isEqual from "react-fast-compare";

class EmployeeName extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {id, employees, short} = this.props;

    let name = short ? "Unknown" : "Unknown Employee";
    if ( employees && employees[id] ) {
      if ( !short ) {
        name = employees[id];
      } else {
        const names = employees[id].split(" ");
        name = names[0];
      }

    }

    // Return Name
    return (<>{name}</>);
  }
}

function mapStateToProps(state) {
  return {
    employees: state.ApplicationReducer.system.employees,
  };
}

export default connect(mapStateToProps)(EmployeeName);