import React from 'react';
import {connect} from 'react-redux';
import {Overlay} from "@blueprintjs/core";
import StagingStorageBays from "./StagingStorageBays";
import StagingStorageFilters from "./StagingStorageFilters";

class StagingStorageSelection extends React.Component {
  static handleStorageSuccess(payload) {
    //ReduxStore.dispatch(deliveryUpdateItems(payload));
  }

  render() {
    const {selectedItem} = this.props;

    let storageBays;
    if ( selectedItem === 'Other' ) {
      storageBays = null; // TODO: It should actually be the notes section!
    } else {
      storageBays = <StagingStorageBays/>;
    }

    return (
      <Overlay
        isOpen={selectedItem !== null}
        canOutsideClickClose={false}
        className={"DeliveryStorageSelection"}
      >
        <div className={"bp3-overlay-content"}>
          <StagingStorageFilters/>
          {storageBays}

        </div>
      </Overlay>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItem: state.StagingReducer.selectedItem,
    executingStorage: state.StagingReducer.executingStorage,
  };
}

export default connect(mapStateToProps)(StagingStorageSelection);