import React from 'react';
import {Colors} from "@blueprintjs/core/lib/cjs/common/colors";
import Schedule from "../../Lib/Schedule/Schedule";


class QuickAppt extends React.Component {
  render() {
    const {periodStart, periodEnd, id, inquiry, customer, address, start, end, type, lat, lng} = this.props;

    // Initialize Style
    let style = {};

    // Set Initial Left Position based on start day.
    const startGap = start - periodStart;
    let startDay = Math.floor(startGap / 86400);
    if ( startDay === 0 || startDay === 6 || startDay === 7 || startDay === 13 ) return null;
    if ( startDay > 6 ) startDay -= 2;
    let left = ((startDay - 1) * 8.8);

    // Now Adjust the Left based on the start hour.
    const startGapHours = Math.max(0, (startGap % 86400) - 28800) / 3600;
    left += 1.1 * Math.min(8, startGapHours);
    left += 0.1;
    style.left = left + 'vw';


    // Start Width based on the number of days for the appointment
    const days = Math.floor((end - start) / 86400);
    let width = 8.8 * days;

    // Now Increase based on number of hours of the appointment
    const hours = Math.max(0, ((end - start) % 86400)) / 3600;
    width += 1.1 * Math.min(8, hours);
    width -= 0.2;
    style.width = width + 'vw';

    // Color
    let color;
    if ( type === 'install' ) color = Colors.COBALT3;
    else color = Colors.FOREST5;
    style.backgroundColor = color;

    // if ( type === 'install' ) {
    //   const north = Schedule.getNorth(lat);
    //   if ( north !== 0 ) {
    //     style.boxShadow = 'inset rgba(69, 128, 230, 1) 0  ' + (north /2) + 'vw 0 0';
    //   }
    //   const east = Schedule.getEast(lng) * -1;
    //   if ( east !== 0 ) {
    //     style.boxShadow += ', inset rgba(69, 128, 230, 1) ' + (east /2) + 'vw 0 0 0';
    //   }
    //   //console.log(north);
    // }



    //console.log(startDay);

    return (
      <div className="CQV-appt" style={style}>
        {customer}
      </div>
    );
  }
}

export default QuickAppt;