import UnixTime from "../UnixTime/UnixTime";

class Schedule {
  static getToday(schedule, type = null) {
    // Return Nothing
    if ( !schedule || !schedule.length ) return [];

    // We need to get today at midnight
    const timestamp = UnixTime.getTodayStart();

    // Find Valid Schedule
    let validSchedule = [];
    for ( let i = 0; i < schedule.length; i++ ) {
      const item = schedule[i];
      if ( !type || (type && item.type === type) ) {
        if (schedule[i].dates.includes(timestamp)) {
          validSchedule.push(schedule[i]);
        }
      }
    }


    // Return Valid Schedule
    return validSchedule;
  };


  static getDaySchedule(schedule, timestamp, type = null, employeeId = null, members = []) {
    // Return Nothing
    if ( !schedule || !schedule.length ) return [];

    // Add Employee to Members... this is pretty lazy code just to avoid having to refactor this function's parameters.
    members.push(employeeId);
    
    // console.log("== DEBUG HACK HERE ==");
    // console.log(schedule);
    
    // Find Valid Schedule
    let validSchedule = [];
    for ( let i = 0; i < schedule.length; i++ ) {
      const item = schedule[i];
      
      // if ( item.inquiry === "27628" ) {
      //   console.log(item);
      //   console.log(timestamp);
      // }
      
      const validType = (!type || (type && item.type === type));
      for ( let e = 0; e < members.length; e++ ) {
        const employee = parseInt(members[e]);
  
        let validEmployee = (!employee || (employee && item.employees.includes(employee)));
  
        
  
        if ( employee && validEmployee ) {
          const earliestDate = Schedule.getAppointmentEarliestTime(item.schedule, employee, timestamp);
  
          // if ( item.inquiry === "27628" ) {
          //   console.log("Valid Type (" + type + "): " + (validType ? "True" : "False"));
          //   console.log("Valid Employee (" + employee + "): " + (validEmployee ? "True" : "False"));
          //   console.log(earliestDate);
          // }
          
          if ( !earliestDate ) validEmployee = false;
        }
  
  
        
        
        //if ( !type || (type && item.type === type) ) {
        if ( validType && validEmployee ) {
          if (schedule[i].dates.includes(timestamp)) {
            validSchedule.push(schedule[i]);
            break;
          }
        }
      }
    }

    validSchedule.sort((a, b) => {
      const earliestA = Schedule.getAppointmentEarliestTime(a.schedule, members);
      const earliestB = Schedule.getAppointmentEarliestTime(b.schedule, members);
      return earliestA - earliestB;
    });


    // Return Valid Schedule
    return validSchedule;
  }

  static getAppointmentEarliestTime(schedule, employee = null, dateLimit = 0) {
    let earliest = 0;
    //const employeeString = employee ? employee.toString() : '';
    let employeeString;
    Object.keys(schedule).forEach((s) => {
    //for ( let s = 0; s < schedule.length; s++ ) {
      const appt = schedule[s];
      for (let i = 0; i < appt.times.length; i++) {
        const time = appt.times[i];
        const start = parseInt(time.start);
        const maxDateLimit = dateLimit + 86400;
        
        if ( employee && Array.isArray(employee) ) {
          for ( let e = 0; e < employee.length; e++ ) {
            employeeString = employee[e].toString();
            if ( start >= dateLimit && start <= maxDateLimit &&  (!employee || time.employees.includes(employeeString)) ) {
              earliest = earliest ? Math.min(earliest, start) : start;
              break;
            }
          }
        } else {
          employeeString = employee ? employee.toString() : '';
          if ( start >= dateLimit && start <= maxDateLimit &&  (!employee || time.employees.includes(employeeString)) ) {
            earliest = earliest ? Math.min(earliest, start) : start;
          }
        }
      }
    });

    return earliest;
  }

  // TODO: Do I need this? Requires combersome logic....
  static getDateRangeSchedule(start, end) {

  }


  static getEmployeeQuickSchedule(schedule, id, start, end, type = null) {
    let validSchedule = [];



    for ( let i = 0; i < schedule.length; i++ ) {
      const item = schedule[i];
      if ( (type === null || item.type === type ) && (id == null || item.employees.includes(id)) ) {
        Object.keys(item.schedule).forEach((dateKey) => {
        //item.schedule.forEach((dateGroup) => {
          const dateGroup = item.schedule[dateKey];
          //console.log(item.customer);
          //console.log(dateGroup);

          let validItem = {
            id: item.id,
            inquiry: item.inquiry,
            customer: item.customer,
            address: item.address,
            start: 0,
            end: 0,
            lat: item.lat,
            lng: item.lng,
            type: item.type,
          };

          // TODO: Doesn't actually check start or end, needs to.
          dateGroup.times.forEach((time) => {
            if ( id == null || time.employees.includes(id.toString()) ) {
              const timeStart = parseInt(time.start);
              const timeEnd = parseInt(time.end);
              const timeLength = timeEnd - timeStart;
              const timeMidnight = UnixTime.getTimestampStart(timeStart);
              const timeStartShift = timeStart - timeMidnight;
              validItem.start = timeStart;
              validItem.end = timeEnd;

              dateGroup.dates.forEach((date) => {
                const dateTimeStart = date + timeStartShift;
                const dateTimeEnd = dateTimeStart + timeLength;
                if (dateTimeStart >= start && dateTimeEnd <= end ) {
                  validSchedule.push({
                    id: item.id,
                    title: item.title,
                    inquiry: item.inquiry,
                    customer: item.customer,
                    address: item.address,
                    start: dateTimeStart,
                    end: dateTimeEnd,
                    lat: item.lat,
                    lng: item.lng,
                    type: item.type,
                  });
                }
                // const dateTimeStart = date + timeStartShift;
                // const dateTimeEnd = dateTimeStart + timeLength;
                // if ( validItem.start === 0 ) {
                //   validItem.start = timeStart;
                //   validItem.end = timeEnd;
                // } else {
                //   validItem.start = Math.max(Math.min(validItem.start, dateTimeStart), start);
                //   validItem.end = Math.min(Math.max(validItem.end, dateTimeEnd), end);
                // }
              });

              // if ( validItem.start === 0 ) {
              //   validItem.start = timeStart;
              //   validItem.end = timeEnd;
              // }
              // else {
              //   console.log("Adjusting Time");
              //   validItem.start = Math.min(validItem.start, timeStart);
              //   validItem.end = Math.max(validItem.end, timeEnd);
              // }

            }
          });

          // if ( validItem.start !== 0 ) {
          //   validSchedule.push(validItem);
          // }

        });
      }
    }

    // const simple = Schedule.simplifyQuickSchedule(validSchedule);
    // console.log(validSchedule);
    // return simple;

    return validSchedule;
  }

  static getEmployeeQuickSchedule2(schedule, id, start, end, type = null) {
    let validSchedule = [];

    for ( let i = 0; i < schedule.length; i++ ) {
      const item = schedule[i];
      if ( (type === null || item.type === type ) && item.employees.includes(id) ) {
        Object.keys(item.schedule).forEach((dateKey) => {
          //item.schedule.forEach((dateGroup) => {
          const dateGroup = item.schedule[dateKey];
          //console.log(item.customer);
          //console.log(dateGroup);
          dateGroup.times.forEach((time) => {
            if ( time.employees.includes(id.toString()) ) {

              validSchedule.push({
                id: item.id,
                inquiry: item.inquiry,
                customer: item.customer,
                address: item.address,
                start: parseInt(time.start),
                end: parseInt(time.end),
                type: item.type,
                x: 0,
              });
            }
          });
        });
      }
    }

    //const simple = Schedule.simplifyQuickSchedule(validSchedule);
    //console.log(simple);
    //return simple;

    return validSchedule;
  }

  static simplifyQuickSchedule(complexSchedule) {

    let schedule = complexSchedule.sort((a, b) => {
      if ( a.start === b.start ) return 0;
      else return a.start > b.start ? 1 : -1;
    });

    let simple = {};
    schedule.forEach((item) => {

      // Create The First Entry
      if ( !simple[item.id] ) {
        simple[item.id] = item;
      }

      // Otherwise, update the existing one!
      else {

        let suffix = 0;
        let match = null;
        let added = false;
        while ( match === null ) {
          const key = suffix === 0 ? (item.id) : (item.id + '-' + suffix);

          if ( simple[key] ) {

            let gap;
            if ( item.end > simple[key].start ) {
              gap = item.end - simple[key].start;

              if ( item.id === "6931" ) {
                console.log("=== TIFFANY ===");
                console.log(key);
                console.log(gap);
                console.log("Item is After First Item!");

                console.log("-----");
              }
              if ( gap >= 86400 && gap < 172800 ) {
                match = true;
                simple[key].end = item.end;
                simple[key].x++;
              } else {
                match = false;
              }
            } else {
              gap = simple[key].end - item.start;
              if ( gap >= 86400 && gap < 172800 ) {
                match = true;
                simple[key].start = item.start;
                simple[key].x++;
              } else {
                match = false;
              }
            }
          }

          if ( match === false ) {
            simple[key] = item;
          } else {
            suffix++;
          }
        }




      }
    });

    console.log(simple);

    // Now convert it back to an array!
    let final = [];
    Object.keys(simple).forEach((s) => {
      final.push(simple[s]);
    });
    return final;
  }

  static getNorth(lat) {
    if ( !lat ) return 0;
    const offset = lat - 47.7;
    if ( offset < 0 ) {
      return Math.max(-5, Math.floor(offset / 0.075));
    } else {
      return Math.min(5, Math.ceil(offset / 0.075));
    }
  }

  static getEast(lng) {
    if ( !lng ) return 0;
    const offset = lng + 122.25;
    if ( offset < 0 ) {
      return Math.max(-5, Math.floor(offset / 0.035));
    } else {
      return Math.min(5, Math.ceil(offset / 0.035));
    }
  }
}

export default Schedule;
