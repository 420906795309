import React from 'react';
import {Callout} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";

class GPSStatus extends React.Component {
  render() {
    const {available, enabled, coords} = this.props;

    // Warning Message
    if ( !available || !enabled || !coords ) {
      let intent = "primary";
      let title = "Requesting GPS Coordinates";
      let message = (
        <React.Fragment>
          <Typography>
            You must enable GPS on your device to use the time clock.
            If your device does not have GPS, or is unable to receive a signal, please contact your manager or Human Resources.
          </Typography>
        </React.Fragment>
      );

      if ( !available ) {
        intent = "warning";
        title = "GPS Unavailable";
      } else if ( !enabled ) {
        intent = "danger";
        title = "GPS Disabled";
      } else {
        message = "This application requires GPS Coordinates for time clock functionality.";
      }

      return (
        <div style={{padding: '2vw'}}>
          <Callout
            intent={intent}
            title={title}
          >{message}</Callout>
        </div>
      );
    }

    // Return Simple Status Message
    return (
      <div>
        <small>GPS Coordinates: {coords.latitude.toFixed(6)}, {coords.longitude.toFixed(6)}</small>
      </div>
    );
  }
}

export default GPSStatus;