import React from 'react';
import {Typography} from "@material-ui/core";
import UnixDate from "../UnixDate/UnixDate";

class TimeClockLogItem extends React.PureComponent {
  render() {
    const {title, start, end} = this.props;

    // Class Name
    let className = "TimeClockLog-Item";

    // Time Text
    let timeText;
    if ( end ) {
      timeText = (<><UnixDate timestamp={start} format="h:mm"/> - <UnixDate timestamp={end} format="h:mm A"/></>);
    } else {
      timeText = (<UnixDate timestamp={start} format="h:mm A"/>);
      className += " active";
    }

    return (
      <div className={className}>
        <Typography variant="caption" className="TimeClockLog-Item-Date">
          <UnixDate timestamp={start} format="M/DD"/>
        </Typography>
        <Typography variant="caption" className="TimeClockLog-Item-Title">
          { title ? title.substring(0, 30) : ""}
        </Typography>
        <Typography variant="caption" className="TimeClockLog-Item-Time">
          {timeText}
        </Typography>
      </div>
    );
  }
}

export default TimeClockLogItem;