import React from 'react';
import {Button} from "@blueprintjs/core";
import TimeClockPanel from "../TimeClock/TimeClockPanel";
import ScheduleView from "../Schedule/ScheduleView";
import ReduxStore from "../../ReduxStore";
import {applicationChangeMode, applicationLogOut} from "../../Actions/ApplicationActions";
import {connect} from "react-redux";
import DeliveryPanel from "../Delivery/DeliveryPanel";
import LoadingPanel from "../Loading/LoadingPanel";
import StagingPanel from "../Staging/StagingPanel";
import ScannerPanel from "../Scanner/ScannerPanel";

class Quo360Panel extends React.Component {

  handleModeSelect = (component, title) => () => {
    this.props.dispatch(applicationChangeMode(component));
    this.props.openPanel({
      component: component,
      title: title,
    });
  };

  handleLogOut = () => {
    ReduxStore.dispatch(applicationLogOut());
  };




  render() {
    const {permission} = this.props;
    return (
      <div className="Quo360Panel">
        <div className="app-list">
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="time"
              text="Time Clock"
              large={true}
              onClick={this.handleModeSelect(TimeClockPanel, 'Time Clock')}
              disabled={permission.indexOf('timeclock') === -1}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="th"
              text="Time Sheet"
              large={true}
              disabled={permission.indexOf('timesheet') === -1}
              //onClick={this.handleModeSelect(ScheduleView, 'Schedule')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="timeline-events"
              text="Schedule"
              large={true}
              onClick={this.handleModeSelect(ScheduleView, 'Schedule')}
              disabled={permission.indexOf('schedule') === -1}
            />
          </div>
        </div>
        <div className="app-list">
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="box"
              text="Deliveries"
              large={true}
              disabled={permission.indexOf('delivery') === -1}
              onClick={this.handleModeSelect(DeliveryPanel, 'Deliveries')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="tag"
              text="Staging"
              large={true}
              disabled={permission.indexOf('staging') === -1}
              onClick={this.handleModeSelect(StagingPanel, 'Staging')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="import"
              text="Load"
              large={true}
              disabled={permission.indexOf('loading') === -1}
              onClick={this.handleModeSelect(LoadingPanel, 'Loading')}
            />
          </div>
        </div>
        <div className="app-list">
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="barcode"
              text="Scanner"
              large={true}
              disabled={permission.indexOf('scanner') === -1}
              onClick={this.handleModeSelect(ScannerPanel, 'Scanner')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="clean"
              text="Reviews"
              large={true}
              disabled={true}
              //onClick={this.handleModeSelect(ScheduleView, 'Schedule')}
            />
          </div>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="chat"
              text="Inquiries"
              large={true}
              disabled={true}
              //onClick={this.handleModeSelect(TimeClockPanel, 'Time Clock')}
            />
          </div>

        </div>
        <div className="app-list">
          <div className="app-list-selection"/>
          <div className="app-list-selection"/>
          <div className="app-list-selection">
            <Button
              fill={true}
              icon="log-out"
              text="Log Out"
              large={true}
              onClick={this.handleLogOut}
              intent={"danger"}
            />
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permission: state.ApplicationReducer.system.permission,
  };
}

export default connect(mapStateToProps)(Quo360Panel);