import Config from "../../Config/Config";
import ReduxStore from "../../ReduxStore";
import {applicationDataError, applicationUpdateState} from "../../Actions/ApplicationActions";

class DataLoaderClass {
  handleData(data, options) {
    // Log Data on Development Builds
    if ( process.env.NODE_ENV !== 'production' ) console.log(data);

    // TODO: Handle Undefined Data Loads...?
    if ( data === undefined ) {
      return;
    }

    // TODO: Handle Malformed JSON responses.

    // TODO: Handle System Errors

    // Handle Application Changes: Only works with Non Crap Data


    // Check for Entities to Load!
    // if ( data['@entities'] !== undefined && data['@entities'] !== null ) {
    //   this.setApplicationEntities(data['@entities']);
    // }

    if ( data['@error'] !== undefined ) {
      ReduxStore.dispatch(applicationDataError(data['@error']));
      if ( options.onError ) {
        options.onError(data['@error'], options);
      }
    } else {
      ReduxStore.dispatch(applicationUpdateState(data));
      if (options.onSuccess) {
        options.onSuccess(data['@payload'] || {}, data['@context']);
      }
    }
  }

  async submitData(path, options = {}) {
    // Build the URL
    let url = this.getRequestUrl(path);
    if ( options.params && typeof options == 'object' ) {
      Object.keys(options.params).forEach((param) => {
        url += "&" + param + "=" + options.params[param];
      });
    }

    // Build the Request
    let request = {
      method: 'post',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Application': 'QuoApp',
        'X-Version': Config.APP_VERSION,
      },
      body: JSON.stringify(options.data || {}),
    };

    // Attach Variables
    let response = await fetch(url, request);

    // Load the JSON Data
    let data = await response.json();
    this.handleData(data, options);
  }

  async loadData(path, options = {}) {
    // Build the Target URL
    let url = this.getRequestUrl(path);
    if ( options.params && typeof options == 'object' ) {
      Object.keys(options.params).forEach((param) => {
        url += "&" + param + "=" + options.params[param];
      });
    }

    // Build the Request
    let request = {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Application': 'QuoApp',
        'X-Version': Config.APP_VERSION,
      },
    };

    // Load Data
    let response = await fetch(url, request);

    // Load the JSON Data
    let data = await response.json();
    this.handleData(data, options);
  }

  /**
   * Builds the Request URL (And Validates the Path)
   * @param path
   */
  getRequestUrl(path) {
    let url = Config.APP_SOURCE;
    if ( path !== undefined && path !== '' ) url += '?path=' + path;
    return url;
  }
}


const DataLoader = new DataLoaderClass();
export default DataLoader;
