import React from 'react';
import {Card} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";
import UnixDate from "../UnixDate/UnixDate";
import ReduxStore from "../../ReduxStore";
import {deliverySetSelection} from "../../Actions/DeliveryActions";
import DeliverySelectionPanel from "./DeliverySelectionPanel";
import DeliveryCardTags from "./DeliveryCardTags";

class DeliveryCard extends React.Component {

  handleSelection = () => {
    const delivery = this.props.delivery;
    ReduxStore.dispatch(deliverySetSelection(this.props.index));
    this.props.openPanel({
      component: DeliverySelectionPanel,
      title: '#' + delivery.inquiry + '-' + delivery.id,
    });
  };



  render() {
    const {delivery} = this.props;

    let notes;
    if ( this.props.openPanel && delivery.notes ) {
      notes = (
        <Typography variant={"subtitle1"} className={"DeliveryCard-Notes"}>
          {delivery.notes}
        </Typography>
      );
    }

    let className = "Delivery-Card";
    //if ( this.isComplete() ) className += " Complete";

    return (
      <Card
        interactive
        className={className}
        onClick={this.props.openPanel ? this.handleSelection : null}
      >
        <Typography>
          <b>#{delivery.inquiry}-{delivery.id}: {delivery.customer}</b>
        </Typography>
        <DeliveryCardTags delivery={delivery}/>
        {notes}
      </Card>
    );
  }
}

export default DeliveryCard;