import React from 'react';
import {Button, Card, Icon, Tag} from "@blueprintjs/core";
import {Typography} from "@material-ui/core";
import ReduxStore from "../../ReduxStore";
import {deliverySetItemSelection, deliverySetSelection} from "../../Actions/DeliveryActions";

class DeliveryItemCard extends React.Component {
  state = {expanded: false};

  handleToggle = () => {
    this.setState({expanded: !this.state.expanded});
  };

  handleItemSelection = () => {
    ReduxStore.dispatch(deliverySetItemSelection(this.props.item));
  };

  render() {
    const {item} = this.props;

    // Build Tags
    const tags = [];
    tags.push(
      <Tag
        key={"confirm"}
        interactive
        icon={<Icon icon={"confirm"} iconSize={11}/>}
        className={item.location || (item.storage && item.storage === 'INVALID') ? "DeliveryItemCard-Tag-Received" : "DeliveryItemCard-Tag-Shipping"}
        onClick={this.handleItemSelection}
      />
    );

    tags.push(<Tag key={"position"} className={"DeliveryItemCard-Tag-Position"}>#{item.position.toString().padStart(3, '0')}</Tag>);
    if ( item.type === 'product' ) {
      if ( item.product ) {
        tags.push(
          <Tag key={"product"} intent={"primary"}>
            {item.product.charAt(0).toUpperCase() + item.product.slice(1)}
          </Tag>
        );
      }
    }
    else {
      if (item.type) {
        tags.push(
          <Tag key={"type"} intent={"primary"}>
            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
          </Tag>
        );
      }
    }
    if ( item.service ) {
      tags.push(
        <Tag key={"service"} intent={"danger"}>Service</Tag>
      );
    }

    // Location Tag
    if ( item.location ) {
      tags.push(
        <Tag key={"location"} className={"bp3-intent-data"}>
          {item.location.charAt(0).toUpperCase() + item.location.slice(1)}
        </Tag>
      );
    } else {
      tags.push(
        <Tag key={"location"} className={"bp3-intent-bling"}>
          Shipping
        </Tag>
      );
    }

    // Storage Tag
    if ( item.storageBay && item.storageBay === 'INVALID' ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-danger"}>
          Not Delivered
        </Tag>
      );
    } else if ( item.storageBay ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-success"}>
          {item.storageBay}
        </Tag>
      );
    } else if ( item.location ) {
      tags.push(
        <Tag key={"storageBay"} className={"bp3-intent-warning"}>
          Unstaged
        </Tag>
      );
    }

    // Item Description
    let description;
    if ( this.state.expanded ) {
      description = (
        <Typography variant={"subtitle1"} className={"DeliveryItemCard-Description"}>
          {item.description}
        </Typography>
      );
    }

    // Vendor Position
    if ( item.vendorPosition ) {
      tags.push(
        <Tag key={"vendorPosition"} className={"DeliveryItemCard-Tag-VendorPosition"}>
          #{item.vendorPosition.toString().padStart(3, '0')}
        </Tag>
      );
    }


    return (
        <Card className={"DeliveryItemCard"}>
          <div className={"DeliveryItemCard-Flex"}>
            <div>
              {tags}
              <Typography className={"DeliveryItemCard-Title"}><b>{item.title}</b></Typography>
            </div>
            <div className={"DeliveryItemCard-Toggle"} style={{textAlign: 'right'}}>
              <Button
                small
                minimal
                icon={"caret-down"}
                onClick={this.handleToggle}
              />
            </div>
          </div>
          {description}
        </Card>
    );
  }
}

export default DeliveryItemCard;