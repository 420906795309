import React from 'react';
import {Tag} from "@blueprintjs/core";
import UnixDate from "../UnixDate/UnixDate";

class DeliveryCardTags extends React.Component {
  completedItems() {
    let completed = 0;
    const delivery = this.props.delivery;
    delivery.items.forEach((item) => {
      if ( item.location || (item.storage && item.storage === 'INVALID') ) {
        completed++;
      }
    });

    return completed;
  }

  render() {
    const {delivery} = this.props;
    if ( !delivery ) return null;

    // Initialize Tags
    let tags = [];

    // Item Count Tag
    const completed = this.completedItems();
    if ( completed === 0 ) {
      tags.push(<Tag key={"items"} intent={"danger"}>{delivery.itemCount}</Tag>);
    } else if ( completed === delivery.itemCount ) {
      tags.push(<Tag key={"items"} intent={"success"}>0</Tag>);
    } else {
      const remaining = delivery.itemCount - completed;
      tags.push(<Tag key={"items"} intent={"warning"}>{remaining}</Tag>);
    }


    // Manufacturer Tags...
    Object.keys(delivery.manufacturers).forEach((manufacturer) => {
      if ( delivery.manufacturers[manufacturer] ) {
        tags.push(<Tag key={manufacturer} intent={"primary"}>{manufacturer}</Tag>);
      }
    });

    // Delivery Date Date
    tags.push(
      <Tag key={"deliveryDate"} icon={"box"} className={"bp3-intent-data"}>
        <UnixDate timestamp={delivery.deliveryDate}/>
      </Tag>
    );

    // Installation Date
    if ( delivery.firstInstall ) {
      tags.push(
        <Tag key={"installDate"} icon={"build"}>
          <UnixDate timestamp={delivery.firstInstall}/>
        </Tag>
      );
    }



    // Complete the Render
    return (
      <div>{tags}</div>
    );
  }
}

export default DeliveryCardTags;