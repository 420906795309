import React from 'react';
import {connect} from 'react-redux';
import DeliveryCard from "./DeliveryCard";
import DeliveryItemList from "./DeliveryItemList";
import DeliveryStorageSelection from "./DeliveryStorageSelection";
import {Button} from "@blueprintjs/core";
import DeliveryCompleteForm from "./DeliveryCompleteForm";

class DeliverySelectionPanel extends React.Component {
  render() {
    const {delivery, closePanel} = this.props;

    return (
      <div className={"DeliverySelectionPanel"}>
        <DeliveryCard delivery={delivery}/>
        <DeliveryItemList items={delivery.items}/>
        <DeliveryStorageSelection/>
        <DeliveryCompleteForm
          items={delivery.items}
          deliveryId={delivery.id}
          notes={delivery.notes}
          closePanel={closePanel}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedDelivery = state.DeliveryReducer.selectedDelivery;
  return {
    delivery: state.DeliveryReducer.deliveries[selectedDelivery],
  };
}

export default connect(mapStateToProps)(DeliverySelectionPanel);