import DataLoader from '../../Lib/Api/DataLoader';

const ApplicationReducer = (state, action) => {
  let newState;
  switch ( action.type ) {
    case 'APPLICATION_INITIALIZE':
      DataLoader.loadData('initialize', {
        params: {location: window.location.href},
        //onSuccess: applicationInitializeCallback,
      });
      return state;

    // case 'APPLICATION_SET_INITIALIZATION':
    //   newState = Object.assign({}, state);
    //   newState.system = action.initialization;
    //   newState.authentication = {access: true};
    //   newState.initialized = true;
    //   return newState;

    // case 'APPLICATION_REDIRECT':
    //   newState = Object.assign({}, state);
    //   newState.redirect = action.redirect;
    //   return newState;

    case 'APPLICATION_SET_AUTHENTICATION':
      newState = Object.assign({}, state);
      newState.authentication = action.authentication;
      newState.initialized = true;
      return newState;

    case 'APPLICATION_LOAD_DATA':
      DataLoader.loadData(action.path, action.options);
      const loadingGet = action.options.loading !== undefined ? action.options.loading : 'loading';
      //const loadingGet = action.options.loading || 'loading';
      if ( loadingGet !== state.loading ) {
        newState = Object.assign({}, state);
        newState.loading = loadingGet;
        return newState;
      } else {
        return state;
      }

    case 'APPLICATION_SUBMIT_DATA':
      DataLoader.submitData(action.path, action.options);
      const loadingSubmit = action.options.loading || 'submit';
      if ( loadingSubmit !== state.loading ) {
        newState = Object.assign({}, state);
        newState.loading = loadingSubmit;
        return newState;
      } else {
        return state;
      }

    case 'APPLICATION_DATA_ERROR':
      newState = Object.assign({}, state);
      newState.loading = false;
      return newState;
      //
      // if ( data['@error'] ) {
      //   if ( data['@error'].code === 74202 ) {
      //     console.log("Resetting login submission");
      //     newState.loading = false;
      //   }
      // }

    case 'APPLICATION_UPDATE_STATE':
      // Create a New State
      const data = action.data;
      let modified = false;
      newState = Object.assign({}, state);

      if ( data['@version'] ) {
        newState.newVersion = true;
      }

      // Authentication
      if ( data['@authentication'] ) {
        modified = true;
        newState.authentication = data['@authentication'];
        newState.initialized = true;
      }

      // System Changes
      if ( data['@system'] ) {
        modified = true;
        newState.system = Object.assign({}, state.system, data['@system']);
        newState.authentication = {access: true};
        newState.initialized = true;
        //console.log("APPLICATION_INITIALIZED");
      }



      // Redirect
      if ( data['@redirect'] ) {
        modified = true;
        newState.redirect = data['@redirect'];
      }

      // When the User ID Changes
      if ( data['@context'] && data['@context'].user !== newState.user ) {
        modified = true;
        newState.user = data['@context'].user;
      }

      if ( state.loading !== false ) {
        modified = true;
        newState.loading = false;
      }

      // Return the State
      if ( modified ) return newState;
      else return state;

    case 'APPLICATION_LOG_OUT':
      return Object.assign({}, state, {
        authentication: {
          access: false,
        },
      });


    default:
      if ( state === undefined ) {
        return {
          //submitting: false,
          loading: false,
          initialized: false,
          redirect: null,
          user: 0,
          newVersion: false,

          system: {
            activeEmployee: 0,
            activeMembers: [],
            agents: {},
            activeAgents: {},
            map: {},
            stores: {},
            employees: {},
            sources: {},
            campaigns: {},
            questionTypes: {},
            documentTypes: {},
            assignments: [],
            contactAssignments: [],
            accounts: {},
            schedule: [],
            permission: [],
          },
          authentication: {
            access: false,
          },
        }
      } else {
        return state;
      }
  }
};

export default ApplicationReducer;
