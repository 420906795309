import React from 'react';
import {Tag} from "@blueprintjs/core";
import UnixDate from "../UnixDate/UnixDate";

class LoadingCardTags extends React.Component {
  render() {
    const {installation} = this.props;
    if ( !installation ) return null;

    // Initialize Tags
    let tags = [];

    // Item Count Tag
    tags.push(<Tag key={"items"} intent={"danger"}>{installation.itemCount}</Tag>);

    // Manufacturer Tags...
    Object.keys(installation.manufacturers).forEach((manufacturer) => {
      if ( installation.manufacturers[manufacturer] ) {
        tags.push(<Tag key={manufacturer} intent={"primary"}>{manufacturer}</Tag>);
      }
    });

    // Installation Date
    if ( installation.firstInstall ) {
      tags.push(
        <Tag key={"installDate"} icon={"build"}>
          <UnixDate timestamp={installation.firstInstall}/>
        </Tag>
      );
    }



    // Complete the Render
    return (
      <div>{tags}</div>
    );
  }
}

export default LoadingCardTags;